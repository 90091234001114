import * as yup from "yup";
import moment from "moment";
import {
  CustomerTypes,
  Genders,
  IdentityCardTypes,
  Nationalities,
  NeighbourhoodClasses,
  ServiceClasses,
  ServiceTypes,
  Titles,
} from "apollo/data";
import lodash from "lodash";
import { validators } from "utils";
import {
  NeighbourhoodClass,
  ServiceClass,
  ServiceType,
} from "../create-regularization/schema";
import {
  CustomerType,
  Gender,
  IdentityCardType,
  Title,
} from "components/cards/service-regularization-details/schema";
export interface ICreateRegularizationsInitiationFormSchema {
  regularizationsInfo: {
    region: {
      _id: string;
      code: string;
      name: string;
    };
    district: {
      _id: string;
      code: string;
      name: string;
    };
    description: string;
    forced: boolean;
  };
  initiationsInfo: {
    initiations: {
      customerType: CustomerType;
      organizationName: string;
      taxIdentificationNumber: string;
      organizationRegistrationNumber: string;
      organizationRegistrationDate: Date;
      organizationRegistrationDocumentUrl: string;
      certificateOfIncorporationDocumentUrl: string;
      title: Title;
      representativeName: string;
      nationality: string;
      dateOfBirth: Date;
      gender: Gender;
      phoneNumber: string;
      emailAddress: string;
      profileImageUrl: string;
      //TODO: Change to boolean
      hasGhanaCard: string;
      ghanaCardNumber: string;
      ghanaCardIssueDate: Date;
      ghanaCardExpiryDate: Date;
      ghanaCardFrontImageUrl: string;
      ghanaCardBackImageUrl: string;
      identityCardType: IdentityCardType;
      identityCardNumber: string;
      identityCardIssueDate: Date;
      identityCardExpiryDate: Date;
      identityCardFrontImageUrl: string;
      identityCardBackImageUrl: string;
      propertyOwnerName: string;
      propertyOwnerPhoneNumber: string;
      ghanaPostAddress: string;
      community: string;
      streetName: string;
      houseNumber: string;
      structureNumber: string;
      landmark: string;
      premiseTypeCode: string;
      premiseCategoryCode: string;
      activityCode: string;
      subActivityCode: string;
      longitude: number;
      latitude: number;
      sitePlanDocumentUrl: string;
      serviceType: ServiceType;
      serviceClass: ServiceClass;
      meterPhase: number;
      energyCertificateIssuerId: string;
      energyCertificateIssuerName: string;
      energyCertificateIssuerPhone: string;
      energyCertificateNumber: string;
      energyCertificateDocumentUrl: string;
      neighbourhoodClass: NeighbourhoodClass;
      numberOfRooms: number;
      numberOfMonths: number;
      meterNumber: string;
      meterImageUrl: string;
    }[];
  };
}

export const RegularizationsInfoFormSchema = yup.object().shape({
  region: yup.object().nullable().required("Required"),
  district: yup.object().nullable().required("Required"),
  description: yup.string().required("Required"),
});

export const InitiationsInfoFormSchema = yup.object().shape({
  initiations: yup
    .array()
    .of(
      yup.object().shape({
        customerType: yup
          .string()
          .oneOf([...CustomerTypes])
          .required("Kindly select customer type"),
        organizationName: yup.string().when("customerType", {
          is: "Individual",
          then: (schema) => schema.notRequired(),
          otherwise: (schema) =>
            schema.required("Organization name is required"),
        }),
        taxIdentificationNumber: yup.string().when("customerType", {
          is: "Individual",
          then: (schema) => schema.notRequired(),
          otherwise: (schema) =>
            schema
              .matches(
                /^(C|P)\d{10}$/,
                "Enter a valid Tax Identification Number"
              )
              .required("Tax Identification is required"),
        }),

        organizationRegistrationNumber: yup.string().when("customerType", {
          is: "Individual",
          then: (schema) => schema.notRequired(),
          otherwise: (schema) =>
            schema
              .matches(/^CS\d{9}$/, "Enter a valid registration number")
              .required("Registration number is required"),
        }),
        organizationRegistrationDate: yup.date().when("customerType", {
          is: "Individual",
          then: (schema) => schema.notRequired(),
          otherwise: (schema) =>
            schema.max(new Date()).required("Registration date is required"),
        }),
        organizationRegistrationDocumentUrl: yup.string().when("customerType", {
          is: "Individual",
          then: (schema) => schema.notRequired(),
          otherwise: (schema) => schema.url().required(),
        }),
        certificateOfIncorporationDocumentUrl: yup
          .string()
          .when("customerType", {
            is: "Individual",
            then: (schema) => schema.notRequired(),
            otherwise: (schema) => schema.url().required(),
          }),
        title: yup
          .string()
          .oneOf([...Titles])
          .required("Kindly select title"),
        representativeName: yup.string().required("Name is required"),
        nationality: yup
          .string()
          .oneOf(lodash.map(Nationalities, "nationality"))
          .required("Kindly select nationality"),
        dateOfBirth: yup
          .date()
          .max(
            moment().subtract(18, "years").toDate(),
            "Customer must be at least 18 years"
          )
          .required("Date of birth is required"),
        gender: yup
          .string()
          .oneOf([...Genders])
          .required("Kindly select gender"),
        phoneNumber: yup
          .string()
          .matches(
            /^0(2|5)(0|2|3|4|5|6|7|9)\d{7}$/,
            "Kindly enter a valid phone number"
          )
          .required("Phone number is required"),
        emailAddress: yup
          .string()
          .email("Kindly enter a valid email address")
          .notRequired(),
        profileImageUrl: yup.string().url().notRequired(),
        hasGhanaCard: yup
          .string()
          .oneOf(["Yes", "No"])
          .when("customerType", {
            is: "Individual",
            then: yup
              .string()
              .required("Kindly select if customer has Ghana Card"),
            otherwise: yup.string().notRequired(),
          }),
        ghanaCardNumber: yup
          .string()
          .matches(/^GHA-\d{9}-\d$/, "Kindly enter a valid Ghana Card Number")
          .when("hasGhanaCard", {
            is: "Yes",
            then: yup.string().required("Ghana Card Number is required"),
            otherwise: yup.string().notRequired(),
          }),
        ghanaCardIssueDate: yup.date().when("hasGhanaCard", {
          is: "Yes",
          then: yup.date().required("Ghana Card issue date is required"),
          otherwise: yup.date().notRequired(),
        }),
        ghanaCardExpiryDate: yup.date().when("hasGhanaCard", {
          is: "Yes",
          then: yup.date().required("Ghana Card expiry date is required"),
          otherwise: yup.date().notRequired(),
        }),
        ghanaCardFrontImageUrl: yup
          .string()
          .url()
          .when("hasGhanaCard", {
            is: "Yes",
            then: yup
              .string()
              .required("Kindly attach front image of Ghana Card"),
            otherwise: yup.string().notRequired(),
          }),
        ghanaCardBackImageUrl: yup
          .string()
          .url()
          .when("hasGhanaCard", {
            is: "Yes",
            then: yup
              .string()
              .required("Kindly attach rear image of Ghana Card"),
            otherwise: yup.string().notRequired(),
          }),
        identityCardType: yup
          .string()
          .oneOf([...IdentityCardTypes])
          .when("hasGhanaCard", {
            is: "No",
            then: yup.string().required("Kindly select ID Card type"),
            otherwise: yup.string().notRequired(),
          }),
        identityCardNumber: yup.string().when("hasGhanaCard", {
          is: "No",
          then: yup.string().required("Kindly enter ID Card number"),
          otherwise: yup.string().notRequired(),
        }),
        identityCardIssueDate: yup.date().when("hasGhanaCard", {
          is: "No",
          then: yup.date().required("Kindly enter ID Card issue date"),
          otherwise: yup.date().notRequired(),
        }),
        identityCardExpiryDate: yup.date().when("hasGhanaCard", {
          is: "No",
          then: yup.date().required("Kindly enter ID Card expiry date"),
          otherwise: yup.date().notRequired(),
        }),
        identityCardFrontImageUrl: yup
          .string()
          .url()
          .when("hasGhanaCard", {
            is: "No",
            then: yup.string().required("Kindly attach front image of ID Card"),
            otherwise: yup.string().notRequired(),
          }),
        identityCardBackImageUrl: yup
          .string()
          .url()
          .when("hasGhanaCard", {
            is: "No",
            then: yup.string().when("identityCardType", {
              is: "DriversLicense",
              then: (schema) =>
                schema.required("Kindly attach rear image of ID Card"),
              otherwise: (schema) => schema.notRequired(),
            }),
            otherwise: yup.string().notRequired(),
          }),
        propertyOwnerName: yup
          .string()
          .required("Kindly enter property owner's name"),
        propertyOwnerPhoneNumber: yup
          .string()
          .matches(
            /^0(2|5)(0|2|3|4|5|6|7|9)\d{7}$/,
            "Kindly enter a valid phone number"
          )
          .required("Phone number is required"),
        ghanaPostAddress: yup
          .string()
          .matches(
            validators.GhanaPostRegex,
            "Kindly enter a valid GhanaPost Address"
          )
          .required("GhanaPost Address is required"),
        community: yup.string().required("Kindly enter community"),
        streetName: yup.string().required("Kindly enter street name"),
        houseNumber: yup.string().required("Kindly enter house number"),
        structureNumber: yup.string().notRequired(),
        landmark: yup.string().required("Kindly enter closest landmark"),
        premiseTypeCode: yup
          .string()
          .required("Kindly enter premise type code"),
        premiseCategoryCode: yup
          .string()
          .required("Kindly enter premise category code"),
        activityCode: yup.string().required("Kindly enter activity code"),
        subActivityCode: yup
          .string()
          .required("Kindly enter sub activity code"),
        longitude: yup.number().min(-180).max(180).required(),
        latitude: yup.number().min(-180).max(180).required(),
        sitePlanDocumentUrl: yup.string().url().notRequired(),
        serviceType: yup
          .string()
          .oneOf(lodash.map(ServiceTypes, "value"))
          .required(),
        serviceClass: yup
          .string()
          .oneOf(lodash.map(ServiceClasses, "value"))
          .required(),
        meterPhase: yup.number().required(),
        energyCertificateIssuerId: yup.string().notRequired(), // There is no validator for this key in the provided code
        energyCertificateIssuerName: yup.string().notRequired(), // There is no validator for this key in the provided code
        energyCertificateIssuerPhone: yup.string().notRequired(), // There is no validator for this key in the provided code
        energyCertificateNumber: yup.string().notRequired(),
        energyCertificateDocumentUrl: yup.string().url().notRequired(),
        neighbourhoodClass: yup
          .string()
          .oneOf(lodash.map(NeighbourhoodClasses, "value"))
          .required(),
        numberOfRooms: yup.number().min(1).required(),
        numberOfMonths: yup.number().min(6).required(),
        meterNumber: yup.string().when("hasMeter", {
          is: true,
          then: yup.string().required("Kindly enter meter number"),
          otherwise: yup.string().notRequired(),
        }),
      })
    )

    .min(1, "The document should contain atleast a record in it"),
});

export const CreateRegularizationsInitiationFormSchema = yup.object().shape({
  regularizationsInfo: RegularizationsInfoFormSchema.required(),
  initiationsInfo: InitiationsInfoFormSchema.required(),
});
