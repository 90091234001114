import { TextInput } from "components/core";
import { useFormik } from "formik";
import { FC } from "react";
import { classNames, wrapClick } from "utils";
import { DistrictPicker, RegionPicker } from "containers";
import { InformationCircleIcon } from "@heroicons/react/24/outline";
import {
  RegularizationsInfoFormSchema,
  ICreateRegularizationsInitiationFormSchema,
} from "./schema";
import { Switch } from "@headlessui/react";

interface RegularizationsInfoFormProps {
  handleNext: (
    values: ICreateRegularizationsInitiationFormSchema["regularizationsInfo"]
  ) => void;
  handlePrevious: () => void;
  initialValues: ICreateRegularizationsInitiationFormSchema["regularizationsInfo"];
  values: ICreateRegularizationsInitiationFormSchema;
  handleCancel: () => void;
}

const RegularizationsInfoForm: FC<RegularizationsInfoFormProps> = ({
  initialValues,
  handleNext,
  handleCancel,
}) => {
  const form = useFormik<
    ICreateRegularizationsInitiationFormSchema["regularizationsInfo"]
  >({
    initialValues,
    validationSchema: RegularizationsInfoFormSchema,
    onSubmit: (values) => {
      handleNext(values);
    },
    onReset: () => {
      handleCancel?.();
    },
  });

  return (
    <div className="flex-1 flex flex-col overflow-hidden">
      <div className="rounded-md bg-primary-100 mx-6 mt-3 p-4">
        <div className="flex">
          <div className="flex-shrink-0">
            <InformationCircleIcon
              className="h-5 w-5 text-primary-400"
              aria-hidden="true"
            />
          </div>
          <div className="ml-3 flex-1 md:flex md:justify-between">
            <p className="text-sm text-primary-700">
              Download a sample upload file (Excel)
            </p>
            <p className="mt-3 text-sm md:ml-6 md:mt-0">
              <a
                href="https://subs-templates.s3.us-west-2.amazonaws.com/regularization-template.xlsx"
                target="_blank"
                download
                rel="noreferrer"
                className="whitespace-nowrap font-medium text-primary-700 hover:text-primary-600"
              >
                Click Here to download
                <span aria-hidden="true"> &rarr;</span>
              </a>
            </p>
          </div>
        </div>
      </div>
      <div className="space-y-6 divide-y divide-gray-200 p-6 flex-1 overflow-y-auto">
        <div>
          <span className="text-xs font-light">Regularization Information</span>
          <div className="grid grid-cols-3 gap-6 mt-2">
            <div className="col-span-2">
              <TextInput
                id="description"
                label="Description"
                type="text"
                placeholder="e.g. Adenta June, 2023 3 Phase Paid Up Customers"
                {...form}
              />
            </div>
          </div>
        </div>

        <div className="pt-6">
          <span className="text-xs font-light">District Information</span>
          <div className="grid grid-cols-3 gap-6 mt-2">
            <div className="">
              <RegionPicker
                id="region"
                label="Region"
                placeholder="e.g. Accra East"
                required={true}
                {...form}
              />
            </div>

            {form.values.region && (
              <div className="">
                <DistrictPicker
                  id="district"
                  label="District"
                  placeholder="eg. Adenta "
                  required={true}
                  filter={{ region: form.values.region?._id }}
                  {...form}
                />
              </div>
            )}
          </div>
        </div>

        <div className="pt-6">
          <span className="text-xs font-light">Extra Information</span>
          <div className="grid grid-cols-3 gap-6 mt-2">
          <div className="col-span-2">
            <Switch.Group as="div">
              <span className="flex flex-grow flex-col">
                <Switch.Label
                  as="span"
                  className="text-sm font-medium text-gray-900"
                  passive
                >
                  Forced Regularization
                </Switch.Label>
              </span>
              <div className="flex items-center justify-between mt-1  h-[38px]">
                <Switch.Description as="span" className="text-sm text-gray-500">
                  Regularizations would be created even if we encounter similar
                  data. Toggle on to enable this
                </Switch.Description>
                <Switch
                  checked={form.values.forced}
                  onChange={(val: boolean) => form.setFieldValue("forced", val)}
                  className={classNames(
                    form.values.forced ? "bg-primary-600" : "bg-gray-200",
                    "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
                  )}
                >
                  <span
                    aria-hidden="true"
                    className={classNames(
                      form.values.forced ? "translate-x-5" : "translate-x-0",
                      "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                    )}
                  />
                </Switch>
              </div>
            </Switch.Group>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-gray-50 dark:bg-gray-800 px-4 py-3 sm:py-4 sm:px-6 sm:flex sm:flex-row-reverse border-t border-gray-200">
        <button
          type="button"
          onClick={wrapClick(form.handleSubmit)}
          className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
        >
          Next
        </button>
        <button
          type="button"
          className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 dark:border-gray-600 shadow-sm px-4 py-2 bg-white dark:bg-gray-900 text-base font-medium text-gray-700 dark:text-gray-200 hover:bg-gray-50 hover:dark:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
          onClick={wrapClick(form.resetForm)}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

export default RegularizationsInfoForm;
