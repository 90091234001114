import { FC } from "react";
import moment from "moment";
import { useReactiveVar } from "@apollo/client";
import { currentConfigVar } from "apollo/cache/config";
import _ from "lodash";
import numeral from "numeral";


interface WorkOrderViewProps {
  regularization: {
    meta: any;
    code: string;
    description: string;
    status: string;
    region: {
      code: string;
      name: string;
    }
    district: {
      code: string;
      name: string;
    }
    createdBy: {
      _id: string;
      code: string;
      lastName: string;
      firstName: string;
      profileImageUrl: string;
      phoneNumber: string;
      emailAddress: string;
    };
    createdAt: string;
    updatedAt: string;
  };
}

const WorkOrderView: FC<WorkOrderViewProps> = ({ regularization }) => {
  const { dateFormat } = useReactiveVar(currentConfigVar);

  return (
    <div className="space-y-6 divide-y divide-gray-200 p-6 flex-1">
      <div>
        <span className='text-xs font-light'>Service Order Information</span>
        <div className='grid grid-cols-3 gap-6 mt-2'>
          <div>
            <span className="block text-sm font-light text-gray-700">
              Code
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {regularization?.code || "N/A"}
            </div>
          </div>
          <div>
            <span className="block text-sm font-light text-gray-700">
              Date
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {regularization?.createdAt ? moment(regularization.createdAt).format(dateFormat + " hh:mm A") : "N/A"}
            </div>
          </div>
          <div>
            <span className="block text-sm font-light text-gray-700">
              Status
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {_.startCase(regularization?.status) || "N/A"}
            </div>
          </div>
        </div>
      </div>
      <div className='pt-6'>
        <span className='text-xs font-light'>Initiation Statistics</span>
        <div className='grid grid-cols-3 gap-6 mt-2'>
          <div>
            <span className="block text-sm font-light text-gray-700">
              Total Initiations
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {numeral(regularization?.meta?.totalInitiations).format("0,0")} Orders
            </div>
          </div>
          <div>
            <span className="block text-sm font-light text-gray-700">
              Successful Initiations
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {numeral(regularization?.meta?.totalSuccessInitiations).format("0,0")} Orders
            </div>
          </div>
          <div>
            <span className="block text-sm font-light text-gray-700">
              Failed Initiations
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {numeral(regularization?.meta?.totalFailedInitiations).format("0,0")} Orders
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WorkOrderView;