import { gql, useLazyQuery, useReactiveVar } from "@apollo/client";
import { TextInput } from "components/core";
import { FormikProps, useFormik } from "formik";
import { FC } from "react";
import { wrapClick, classNames } from "utils";
import { RequestInfoSchema, ICreateRegularizationFormSchema } from "./schema";
import moment from "moment";
import { currentConfigVar } from "apollo/cache/config";

const SEARCH_SERVICE_POINT = gql`
  query RegularizationsPreSearch(
    $search: String
    $servicePointsSearchFields: [String!]
    $regularizationsSearchFields: [String!]
  ) {
    servicePoints: getServicePoints(
      search: $search
      searchFields: $servicePointsSearchFields
      pageSize: 6
      page: 0
    ) {
      _id
      code
      qrCode
      geoLocation {
        type
        coordinates
      }
      regionCode
      regionName
      districtCode
      districtName
      address
      ghanaPostAddress
      propertyCode
      customerType
      customerOrganization {
        name
        taxIdentificationNumber
        organizationRegistrationNumber
        organizationRegistrationDate
        organizationRegistrationDocumentUrl
        certificateOfIncorporationDocumentUrl
      }
      customerRepresentative {
        title
        fullName
        nationality
        dateOfBirth
        gender
        phoneNumber
        emailAddress
        profileImageUrl
        hasGhanaCard
        ghanaCardNumber
        ghanaCardIssueDate
        ghanaCardExpiryDate
        ghanaCardFrontImageUrl
        ghanaCardBackImageUrl
        identityCardType
        identityCardNumber
        identityCardIssueDate
        identityCardExpiryDate
        identityCardFrontImageUrl
        identityCardBackImageUrl
      }
      customerCode
      accountCode
      meter {
        _id
        code
        modelMeta {
          modelCode
          modelName
          brandCode
          brandName
          phase
          digits
        }
        modelType
        status
      }
      meterCode
      serviceType
      serviceClass
      tariffClassCode
      tariffClassName
      geoCode
      status
      createdAt
      updatedAt
    }
    regularizations: getRegularizations(
      search: $search
      searchFields: $regularizationsSearchFields
      pageSize: 6
      page: 0
    ) {
      _id
      servicePoint {
        _id
        code
        qrCode
        geoLocation {
          type
          coordinates
        }
        regionCode
        regionName
        districtCode
        districtName
        address
        ghanaPostAddress
        propertyCode
        customerType
        customerOrganization {
          name
          taxIdentificationNumber
          organizationRegistrationNumber
          organizationRegistrationDate
          organizationRegistrationDocumentUrl
          certificateOfIncorporationDocumentUrl
        }
        customerRepresentative {
          title
          fullName
          nationality
          dateOfBirth
          gender
          phoneNumber
          emailAddress
          profileImageUrl
          hasGhanaCard
          ghanaCardNumber
          ghanaCardIssueDate
          ghanaCardExpiryDate
          ghanaCardFrontImageUrl
          ghanaCardBackImageUrl
          identityCardType
          identityCardNumber
          identityCardIssueDate
          identityCardExpiryDate
          identityCardFrontImageUrl
          identityCardBackImageUrl
        }
        customerCode
        accountCode
        meter {
          _id
          code
          modelMeta {
            modelCode
            modelName
            brandCode
            brandName
            phase
            digits
          }
          modelType
          status
        }
        meterCode
        serviceType
        serviceClass
        tariffClassCode
        tariffClassName
        geoCode
        status
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;

interface RequestInfoFormProps {
  handleNext: (values: ICreateRegularizationFormSchema["requestInfo"]) => void;
  handlePrevious: () => void;
  initialValues: ICreateRegularizationFormSchema["requestInfo"];
  values: ICreateRegularizationFormSchema;
  handleCancel: () => void;
  parentForm: FormikProps<ICreateRegularizationFormSchema>;
  step: number;
  lastStep: number;
}

function Owner({
  owner,
  onClick,
  isActive,
}: {
  owner: any;
  onClick: any;
  isActive: boolean;
}) {
  return (
    <button
      onClick={onClick}
      className={classNames(
        isActive
          ? "border-primary-500 dark:border-primary-600 bg-white dark:bg-primary-800 hover:border-primary-700 dark:hover:border-primary-500"
          : "border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-800 hover:border-gray-400 dark:hover:border-gray-500",
        "focus:outline-none relative rounded-lg border px-3 py-3 shadow-sm flex items-center space-x-3 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-primary-500"
      )}
    >
      <div className="flex-1 min-w-0 text-left">
        <p className="text-sm font-medium text-gray-900 dark:text-white truncate">
          {owner?.customerRepresentative?.fullName ||
            owner?.customerOrgarnization?.name ||
            "N A"}
        </p>
        <div className="flex justify-between text-sm text-gray-700">
          <span className="text-gray-500">Account Number</span>
          <span>{owner?.accountCode || "N/A"}</span>
        </div>
        <div className="flex justify-between text-sm text-gray-700">
          <span className="text-gray-500">Geo Code</span>
          <span>{owner?.geoCode || "N/A"}</span>
        </div>
        <div className="flex justify-between text-sm text-gray-700">
          <span className="text-gray-500">Meter Number</span>
          <span>{owner?.meterCode || "N/A"}</span>
        </div>
        <div className="flex justify-between text-sm text-gray-700">
          <span className="text-gray-500">Service Point</span>
          <span>{owner?.code || "N/A"}</span>
        </div>
      </div>
    </button>
  );
}

const RequestInfoForm: FC<RequestInfoFormProps> = ({
  initialValues,
  handleNext,
  handleCancel,
  parentForm,
  step,
  lastStep,
}) => {
  const { dateFormat } = useReactiveVar(currentConfigVar);
  const form = useFormik<ICreateRegularizationFormSchema["requestInfo"]>({
    initialValues,
    validationSchema: RequestInfoSchema,
    onSubmit: (values) => {
      handleNext(values);
    },
    onReset: () => {
      handleCancel?.();
    },
  });
  const [searchServicePoints, { loading, data }] = useLazyQuery(
    SEARCH_SERVICE_POINT,
    {
      fetchPolicy: "network-only",
    }
  );

  const servicePointSearchForm = useFormik({
    initialValues: {
      search: "",
    },
    onSubmit: (values) => {
      searchServicePoints({
        variables: {
          search: values.search,
          regularizationsSearchFields: [
            "accountCode",
            "meterCode",
            "servicePointCode",
            "property.ghanaPostAddress",
            "customerData.organization.name",
            "customerData.representative.fullName",
            "customerData.representative.phoneNumber",
            "customerData.representative.emailAddress",
            "customerData.representative.ghanaCardNumber",
            "billing.meterNumber",
          ],
          servicePointsSearchFields: [
            "accountCode",
            "customerCode",
            "meterCode",
            "code",
            // "ghanaPostAddress",
            // "customerOrganization.name",
            "customerRepresentative.fullName",
            "customerRepresentative.phoneNumber",
            // "customerRepresentative.emailAddress",
            // "customerRepresentative.ghanaCardNumber",
          ],
        },
      });
    },
  });

  return (
    <div className="flex-1 flex flex-col overflow-hidden">
      <div className="space-y-6 divide-y divide-gray-200 p-4 md:p-6 flex-1 overflow-y-auto">
        <div>
          <span className="text-xs font-light">
            Existing Customer Information
          </span>
          <div className="grid grid-cols-3 gap-6 mt-2">
            <div className="col-span-3">
              <div className="flex items-center justify-between">
                <span className="text-sm text-gray-500">
                  Supply Account Number, Customer Number, GeoCode, Service Point
                  Number or Meter Number to find out if customer has already
                  been recognized
                </span>
              </div>
            </div>
          </div>
          <div className="mt-6">
            <span className="text-xs font-light">Search for Account</span>
            <form
              onSubmit={servicePointSearchForm.handleSubmit}
              className="grid grid-cols-6 gap-4 md:gap-6 mt-2"
            >
              <div className="col-span-4">
                <TextInput
                  id="search"
                  label="Search Customer (Account No., Meter No., Geo Code, SPN, Fullname, Phone Number)"
                  type="text"
                  placeholder="e.g. 0200000000"
                  {...servicePointSearchForm}
                />
              </div>

              <div className="col-span-6 sm:col-span-2 items-end justify-end flex">
                <button
                  type="submit"
                  className="w-full inline-flex justify-center rounded-md border border-transparent bg-primary-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
                >
                  {loading ? "Searching Customer..." : "Search Customer"}
                </button>
              </div>
            </form>
            <div className="mt-6 pt-4 border-t border-gray-200">
              <span className="text-sm font-semibold">
                Existing Customers Matched
              </span>
              <div className="grid grid-cols-3 gap-6 mt-3 border-gray-200">
                {data?.servicePoints?.map?.((servicePoint: any) => (
                  <Owner
                    key={servicePoint._id}
                    isActive={
                      servicePoint._id ===
                      form.values?.existingServicePoint?._id
                    }
                    onClick={wrapClick(() =>
                      form.setFieldValue("existingServicePoint", servicePoint)
                    )}
                    owner={servicePoint}
                  />
                ))}
              </div>
            </div>
            <div className="mt-6 pt-4 border-t border-gray-200">
              <span className="text-sm font-semibold">
                Existing Regularizations Matched
              </span>
              <div className="grid grid-cols-3 gap-6 mt-3 border-gray-200">
                {data?.regularizations?.map?.(({servicePoint, _id}: any) => (
                  <Owner
                    key={_id}
                    isActive={
                      _id ===
                      form.values?.existingServicePoint?._id
                    }
                    onClick={wrapClick(() =>
                      form.setFieldValue("existingServicePoint", servicePoint)
                    )}
                    owner={servicePoint}
                  />
                ))}
              </div>
            </div>
            {form?.values?.existingServicePoint && (
              <div className="mt-6 border border-gray-300 border-dashed bg-gray-100 divide-y divide-dashed divide-gray-300 rounded-md">
                <div className="grid grid-cols-4 gap-4 p-4">
                  {/* {JSON.stringify(form?.values?.existingServicePoint, null, 2)} */}
                  <div className="">
                    <span className="block text-sm font-light text-gray-700">
                      Type
                    </span>
                    <div className="mt-1 block w-full sm:text-sm">
                      {form?.values?.existingServicePoint?.customerType ||
                        "N/A"}
                    </div>
                  </div>
                  <div className="">
                    <span className="block text-sm font-light text-gray-700">
                      Title
                    </span>
                    <div className="mt-1 block w-full sm:text-sm">
                      {form?.values?.existingServicePoint
                        ?.customerRepresentative?.title || "N/A"}
                    </div>
                  </div>
                  <div className="col-span-2">
                    <span className="block text-sm font-light text-gray-700">
                      Full Name
                    </span>
                    <div className="mt-1 block w-full sm:text-sm">
                      {form?.values?.existingServicePoint
                        ?.customerRepresentative?.fullName ||
                        form?.values?.existingServicePoint?.customerOrganization
                          ?.name ||
                        "N/A"}
                    </div>
                  </div>
                  <div className="">
                    <span className="block text-sm font-light text-gray-700">
                      Nationality
                    </span>
                    <div className="mt-1 block w-full sm:text-sm">
                      {form?.values?.existingServicePoint
                        ?.customerRepresentative?.nationality || "N/A"}
                    </div>
                  </div>
                  <div className="">
                    <span className="block text-sm font-light text-gray-700">
                      Date of Birth
                    </span>
                    <div className="mt-1 block w-full sm:text-sm">
                      {moment(
                        form?.values?.existingServicePoint
                          ?.customerRepresentative?.dateOfBirth ||
                          form?.values?.existingServicePoint
                            ?.customerOrganization?.organizationRegistrationDate
                      ).format(dateFormat)}
                    </div>
                  </div>
                  <div className="">
                    <span className="block text-sm font-light text-gray-700">
                      Gender
                    </span>
                    <div className="mt-1 block w-full sm:text-sm">
                      {form?.values?.existingServicePoint
                        ?.customerRepresentative?.gender ||
                        form?.values?.existingServicePoint
                          ?.customerRepresentative?.gender ||
                        "N/A"}
                    </div>
                  </div>
                </div>
                <div className="grid grid-cols-4 gap-4 p-4">
                  <div className="">
                    <span className="block text-sm font-light text-gray-700">
                      Account Number
                    </span>
                    <div className="mt-1 block w-full sm:text-sm">
                      {form?.values?.existingServicePoint?.accountCode || "N/A"}
                    </div>
                  </div>
                  <div>
                    <span className="block text-sm font-light text-gray-700">
                      Customer Number
                    </span>
                    <div className="mt-1 block w-full sm:text-sm">
                      {form?.values?.existingServicePoint?.customerCode ||
                        "N/A"}
                    </div>
                  </div>
                  <div>
                    <span className="block text-sm font-light text-gray-700">
                      Property Number
                    </span>
                    <div className="mt-1 block w-full sm:text-sm">
                      {form?.values?.existingServicePoint?.propertyCode ||
                        "N/A"}
                    </div>
                  </div>
                  <div>
                    <span className="block text-sm font-light text-gray-700">
                      Service Point Number
                    </span>
                    <div className="mt-1 block w-full sm:text-sm">
                      {form?.values?.existingServicePoint?.code || "N/A"}
                    </div>
                  </div>
                  <div>
                    <span className="block text-sm font-light text-gray-700">
                      Geographical Code
                    </span>
                    <div className="mt-1 block w-full sm:text-sm">
                      {form?.values?.existingServicePoint?.geoCode || "N/A"}
                    </div>
                  </div>
                  <div>
                    <span className="block text-sm font-light text-gray-700">
                      Meter Number
                    </span>
                    <div className="mt-1 block w-full sm:text-sm">
                      {form?.values?.existingServicePoint?.meter?.code || "N/A"}
                    </div>
                  </div>
                  <div className="col-span-2">
                    <span className="block text-sm font-light text-gray-700">
                      Property Address
                    </span>
                    <div className="mt-1 block w-full sm:text-sm">
                      {form?.values?.existingServicePoint?.address || "N/A"}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="bg-gray-50 dark:bg-gray-800 px-4 py-3 sm:py-4 sm:px-6 sm:flex sm:flex-row-reverse border-t border-gray-200">
        <button
          type="button"
          onClick={wrapClick(form.handleSubmit)}
          className={classNames(
            form.isValid ? "hover:bg-primary-700" : "cursor-not-allowed",
            "w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
          )}
        >
          Next
        </button>
        <button
          type="button"
          className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 dark:border-gray-600 shadow-sm px-4 py-2 bg-white dark:bg-gray-900 text-base font-medium text-gray-700 dark:text-gray-200 hover:bg-gray-50 hover:dark:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
          onClick={wrapClick(form.resetForm)}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

export default RequestInfoForm;
