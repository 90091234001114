import lodash from "lodash";
import { FC } from "react";
import { classNames } from "utils";
import { ICreateRegularizationsInitiationFormSchema } from "./schema";
import { gql, useQuery } from "@apollo/client";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
const GET_REGULARIZATIONS_SIMILARITIES = gql`
  query caimsSimilarity(
    $meterNumbers: [String!]
    $customerPhoneNumbers: [String!]
    $customerNames: [String!]
    $region: ID
    $district: ID
  ) {
    caimsSimilarity: getRegularizationCaimsSimilarity(
      meterNumbers: $meterNumbers
      customerPhoneNumbers: $customerPhoneNumbers
      customerNames: $customerNames
      region: $region
      district: $district
    ) {
      customerNamesCount
      customerPhoneNumbersCount
      meterNumbersCount
    }
  }
`;
export interface BulkUploadInstallationServiceOrdersProps {
  data: ICreateRegularizationsInitiationFormSchema["initiationsInfo"];
  values: ICreateRegularizationsInitiationFormSchema;
}

const headerMap = {
  SN: "SN",
  customerType: "Customer Type",
  organizationName: "Organization Name",
  taxIdentificationNumber: "Tax Identification Number",
  organizationRegistrationNumber: "Organization Registration Number",
  organizationRegistrationDate: "Organization Registration Date",
  organizationRegistrationDocumentUrl: "Organization Registration Document URL",
  certificateOfIncorporationDocumentUrl:
    "Certificate of Incorporation Document URL",
  title: "Title",
  representativeName: "Representative Name",
  nationality: "Nationality",
  dateOfBirth: "Date of Birth",
  gender: "Gender",
  phoneNumber: "Phone Number",
  emailAddress: "Email Address",
  profileImageUrl: "Profile Image URL",
  hasGhanaCard: "Has Ghana Card",
  ghanaCardNumber: "Ghana Card Number",
  ghanaCardIssueDate: "Ghana Card Issue Date",
  ghanaCardExpiryDate: "Ghana Card Expiry Date",
  ghanaCardFrontImageUrl: "Ghana Card Front Image URL",
  ghanaCardBackImageUrl: "Ghana Card Back Image URL",
  identityCardType: "Identity Card Type",
  identityCardNumber: "Identity Card Number",
  identityCardIssueDate: "Identity Card Issue Date",
  identityCardExpiryDate: "Identity Card Expiry Date",
  identityCardFrontImageUrl: "Identity Card Front Image URL",
  identityCardBackImageUrl: "Identity Card Back Image URL",
  propertyOwnerName: "Property Owner Name",
  propertyOwnerPhoneNumber: "Property Owner Phone Number",
  ghanaPostAddress: "Ghana Post Address",
  community: "Community",
  streetName: "Street Name",
  houseNumber: "House Number",
  structureNumber: "Structure Number",
  landmark: "Landmark",
  premiseTypeCode: "Premise Type Code",
  premiseCategoryCode: "Premise Category Code",
  activityCode: "Activity Code",
  subActivityCode: "Sub Activity Code",
  longitude: "Longitude",
  latitude: "Latitude",
  sitePlanDocumentUrl: "Site Plan Document URL",
  serviceType: "Service Type",
  serviceClass: "Service Class",
  meterPhase: "Meter Phase",
  energyCertificateIssuerId: "Energy Certificate Issuer ID",
  energyCertificateIssuerName: "Energy Certificate Issuer Name",
  energyCertificateIssuerPhone: "Energy Certificate Issuer Phone",
  energyCertificateNumber: "Energy Certificate Number",
  energyCertificateDocumentUrl: "Energy Certificate Document URL",
  neighbourhoodClass: "Neighbourhood Class",
  numberOfRooms: "Number of Rooms",
  numberOfMonths: "Number of Months",
  meterNumber: "Meter Number",
  meterImageUrl: "Meter Image URL",
};

const BulkUploadInstallationServiceOrders: FC<
  BulkUploadInstallationServiceOrdersProps
> = ({ data, values }) => {
  const columns = Object.keys(headerMap);
  const { data: similarData, } = useQuery(
    GET_REGULARIZATIONS_SIMILARITIES,
    {
      variables: {
        region: values?.regularizationsInfo?.region?._id,
        district: values?.regularizationsInfo?.district?._id,
        meterNumbers: data?.initiations?.map(
          (initiation) => initiation.meterNumber
        ),
        customerPhoneNumbers: data?.initiations?.map(
          (initiation) => initiation.phoneNumber
        ),
        customerNames: data?.initiations?.map(
          (initiation) => initiation.representativeName
        ),
      },
    }
  );

  return (
    <>
      {/* customerNamesCount
customerPhoneNumbersCount
meterNumbersCount */}
      {similarData?.caimsSimilarity?.customerNamesCount ||
      similarData?.caimsSimilarity?.meterNumbersCount ||
      similarData?.caimsSimilarity?.customerPhoneNumbersCount ? (
        <div className='rounded-md bg-yellow-50 p-4 mb-2'>
          <div className='flex'>
            <div className='flex-shrink-0'>
              <ExclamationTriangleIcon
                className='h-5 w-5 text-yellow-400'
                aria-hidden='true'
              />
            </div>
            <div className='ml-3'>
              <h3 className='text-sm font-medium text-yellow-800'>
                Similarities Detected
              </h3>
              <div className='mt-2 text-sm text-yellow-700'>
                <p>
                  {similarData?.caimsSimilarity?.customerNamesCount &&
                    `${similarData?.caimsSimilarity?.customerNamesCount} existing regularizations have same customer names`}

                  {similarData?.caimsSimilarity?.customerPhoneNumbersCount &&
                    `${similarData?.caimsSimilarity?.customerPhoneNumbersCount} have same phone numbers and`}

                  {similarData?.caimsSimilarity?.meterNumbersCount &&
                    `${similarData?.caimsSimilarity?.meterNumbersCount} found with same meter numbers as the regularizations you are
                    about to upload`}
                </p>
              </div>
              {/* <div className='mt-3'>
              <button
                onClick={() =>
                  navigate({
                    search(prev) {
                      return {
                        ...prev,
                        modal: "update",
                        id: searchParams.id,
                      };
                    },
                  })
                }
                type='button'
                className='rounded-md  bg-yellow-100 px-2 py-1.5 text-sm font-medium text-yellow-800 hover:bg-yellow-100 focus:outline-none focus:ring-2 focus:ring-yellow-600 focus:ring-offset-2 focus:ring-offset-yellow-50'
              >
                Click to Update customer
              </button>
            </div> */}
            </div>
          </div>
        </div>
      ) : (
        <> </>
      )}
      <div className='flex-1 flex flex-col border overflow-x-auto'>
        <table className='min-w-full divide-y divide-gray-200 overflow-x-auto'>
          <thead className='bg-gray-50 sticky top-0 z-10 border-b'>
            <tr>
              {columns?.map((column: any, idx: number) => (
                <th
                  scope='col'
                  key={idx}
                  className={classNames(
                    idx === 0 ? "sticky left-0 bg-gray-50" : "",
                    "px-6 py-3 mt-0 text-left text-xs font-medium whitespace-nowrap text-gray-500 uppercase tracking-wider"
                  )}
                >
                  {lodash.get(headerMap, column)}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className='bg-white divide-y divide-gray-200'>
            {data?.initiations?.map((member, key) => {
              return (
                <tr key={key}>
                  {columns?.map((column, idx) => {
                    return (
                      <td
                        key={idx}
                        className={classNames(
                          idx === 0 ? "sticky left-0 bg-gray-50" : "",
                          "px-6 py-4 whitespace-nowrap text-sm ",
                          "text-gray-500"
                        )}
                      >
                        {idx === 0
                          ? key + 1
                          : lodash.get(member, column, "N/A")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default BulkUploadInstallationServiceOrders;
