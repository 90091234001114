import { NeighbourhoodClass } from "components/forms/create-regularization/schema";
import lodash from "lodash";
import { FC } from "react";
import { classNames } from "utils";
import {
  CustomerType,
  Gender,
  IdentityCardType,
  ServiceClass,
  ServiceType,
  Title,
} from "./service-regularization-details/schema";

export interface WorkOrderInitiationsViewProps {
  initiations: {
    status: string;
    statusReason: string;
    customerType: CustomerType;
    organizationName: string;
    taxIdentificationNumber: string;
    organizationRegistrationNumber: string;
    organizationRegistrationDate: Date;
    organizationRegistrationDocumentUrl: string;
    certificateOfIncorporationDocumentUrl: string;
    title: Title;
    representativeName: string;
    nationality: string;
    dateOfBirth: Date;
    gender: Gender;
    phoneNumber: string;
    emailAddress: string;
    profileImageUrl: string;
    //TODO: Change to boolean
    hasGhanaCard: string;
    ghanaCardNumber: string;
    ghanaCardIssueDate: Date;
    ghanaCardExpiryDate: Date;
    ghanaCardFrontImageUrl: string;
    ghanaCardBackImageUrl: string;
    identityCardType: IdentityCardType;
    identityCardNumber: string;
    identityCardIssueDate: Date;
    identityCardExpiryDate: Date;
    identityCardFrontImageUrl: string;
    identityCardBackImageUrl: string;
    propertyOwnerName: string;
    propertyOwnerPhoneNumber: string;
    ghanaPostAddress: string;
    community: string;
    streetName: string;
    houseNumber: string;
    structureNumber: string;
    landmark: string;
    premiseTypeCode: string;
    premiseCategoryCode: string;
    activityCode: string;
    subActivityCode: string;
    longitude: number;
    latitude: number;
    sitePlanDocumentUrl: string;
    serviceType: ServiceType;
    serviceClass: ServiceClass;
    meterPhase: number;
    energyCertificateIssuerId: string;
    energyCertificateIssuerName: string;
    energyCertificateIssuerPhone: string;
    energyCertificateNumber: string;
    energyCertificateDocumentUrl: string;
    neighbourhoodClass: NeighbourhoodClass;
    numberOfRooms: number;
    numberOfMonths: number;
    meterNumber: string;
    meterImageUrl: string;
  }[];
  type: "Failed" | "Successful";
}

const headers = lodash.toPairs({
  customerType: "Customer Type",
  organizationName: "Organization Name",
  taxIdentificationNumber: "Tax Identification Number",
  organizationRegistrationNumber: "Organization Registration Number",
  organizationRegistrationDate: "Organization Registration Date",
  organizationRegistrationDocumentUrl: "Organization Registration Document URL",
  certificateOfIncorporationDocumentUrl:
    "Certificate of Incorporation Document URL",
  title: "Title",
  representativeName: "Representative Name",
  nationality: "Nationality",
  dateOfBirth: "Date of Birth",
  gender: "Gender",
  phoneNumber: "Phone Number",
  emailAddress: "Email Address",
  profileImageUrl: "Profile Image URL",
  hasGhanaCard: "Has Ghana Card",
  ghanaCardNumber: "Ghana Card Number",
  ghanaCardIssueDate: "Ghana Card Issue Date",
  ghanaCardExpiryDate: "Ghana Card Expiry Date",
  ghanaCardFrontImageUrl: "Ghana Card Front Image URL",
  ghanaCardBackImageUrl: "Ghana Card Back Image URL",
  identityCardType: "Identity Card Type",
  identityCardNumber: "Identity Card Number",
  identityCardIssueDate: "Identity Card Issue Date",
  identityCardExpiryDate: "Identity Card Expiry Date",
  identityCardFrontImageUrl: "Identity Card Front Image URL",
  identityCardBackImageUrl: "Identity Card Back Image URL",
  propertyOwnerName: "Property Owner Name",
  propertyOwnerPhoneNumber: "Property Owner Phone Number",
  ghanaPostAddress: "Ghana Post Address",
  community: "Community",
  streetName: "Street Name",
  houseNumber: "House Number",
  structureNumber: "Structure Number",
  landmark: "Landmark",
  premiseTypeCode: "Premise Type Code",
  premiseCategoryCode: "Premise Category Code",
  activityCode: "Activity Code",
  subActivityCode: "Sub Activity Code",
  longitude: "Longitude",
  latitude: "Latitude",
  sitePlanDocumentUrl: "Site Plan Document URL",
  serviceType: "Service Type",
  serviceClass: "Service Class",
  meterPhase: "Meter Phase",
  energyCertificateIssuerId: "Energy Certificate Issuer ID",
  energyCertificateIssuerName: "Energy Certificate Issuer Name",
  energyCertificateIssuerPhone: "Energy Certificate Issuer Phone",
  energyCertificateNumber: "Energy Certificate Number",
  energyCertificateDocumentUrl: "Energy Certificate Document URL",
  neighbourhoodClass: "Neighbourhood Class",
  numberOfRooms: "Number of Rooms",
  numberOfMonths: "Number of Months",
  meterNumber: "Meter Number",
  meterImageUrl: "Meter Image URL",
  statusReason: "Status Reason",

});

const WorkOrderInitiationsView: FC<WorkOrderInitiationsViewProps> = ({
  initiations,
  type,
}) => {
  if (initiations.length) {
    return (
      <table className='min-w-full divide-y divide-gray-200 overflow-x-scroll'>
        <thead className='bg-gray-50 sticky top-0 z-10'>
          <tr>
            {headers?.map((column: any, idx: number) => (
              <th
                scope='col'
                key={idx}
                className={classNames(
                  idx === 0 ? "sticky left-0 bg-gray-50" : "",
                  "px-6 py-3 mt-0 text-left text-xs font-medium whitespace-nowrap text-gray-500 uppercase tracking-wider"
                )}
              >
                {column?.[1]}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className='bg-white divide-y divide-gray-200'>
          {initiations?.map((initiation, key) => {
            return (
              <tr key={key}>
                {headers?.map((column, idx) => {
                  return (
                    <td
                      key={idx}
                      className={classNames(
                        idx === 0 ? "sticky left-0 bg-gray-50" : "",
                        "px-6 py-4 whitespace-nowrap text-sm ",
                        "text-gray-500"
                      )}
                    >
                      {(initiation as any)[column?.[0]] || "N/A"}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }
  return (
    <div className='flex-1 flex w-full p-6'>
      <div className='relative flex flex-col w-full rounded-lg border-2 border-dashed border-gray-300 p-6 items-center justify-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2'>
        {/* <UserPlusIcon
              className="mx-auto h-10 w-10 text-gray-400"
              stroke="currentColor"
              strokeWidth={1}
              aria-hidden="true"
            /> */}
        <h3 className='mt-2 text-md font-medium text-gray-900'>
          No initiation here
        </h3>
        <p className='mt-1 text-md text-gray-500'>
          {type} initiations would appear here
        </p>
      </div>
    </div>
  );
};

export default WorkOrderInitiationsView;
