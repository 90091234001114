import { FC, useEffect } from "react"
import { gql, useMutation } from '@apollo/client'
import { setAuth } from 'apollo/cache/auth';
import { useNavigate, useSearch } from 'react-location';
import { LocationGenerics } from 'router/location';

const LOGIN = gql`
  mutation LoginHubtel($token: String!){
    login: loginHubtel(token: $token) {
      user {
        _id
        code
        lastName
        firstName
        gender
        phoneNumber
        ghanaCardNumber
        emailAddress
        profileImageUrl
        role {
          _id
          code
          name
          applications 
          permissions
        }
        region {
          _id
        }
        regionCode
        regionName
        district {
          _id
        }
        districtCode
        districtName
        meta {
          isPasswordSet
          lastLoginAt
        }
        createdAt
      }
      tokens {
        authorization
      }
    }
  }
`;

const AuthPage: FC = () => {
  const navigate = useNavigate<LocationGenerics>();
  const search = useSearch<LocationGenerics>();
  const { token, redirect } = search;
  const [login, { loading, error }] = useMutation(LOGIN)

  const __login = async () => {
    await login({ variables: { token } })
      .then(async ({ data }) => {
        if (data?.login) {
          setAuth({
            user: data?.login?.user,
            token: data.login.tokens?.authorization,
          })
          return navigate({
            replace: true,
            to: redirect ?? "/"
          })
        }
      })
      .catch(() => {

      })
  }

  useEffect(() => {
    if (token) {
      __login();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (

    <div className="mx-auto w-full max-w-sm lg:w-96">
      <div>
        <div className="flex items-end flex-row">
          <div className="h-16 w-16 flex-shrink-0 p-1 bg-indigo-800">
            <img
              className="h-14 w-14 border-white border p-1"
              src={require("assets/logo.png")}
              alt="SUBS"
            />
          </div>
          <div className="ml-3">
            <span className="text-lg text-primary-600 font-mono">CAIMS <br />Console</span>
          </div>
        </div>
        <h2 className="mt-6 text-3xl font-extrabold text-gray-900">CAIMS Console</h2>
        <p className="mt-2 text-sm text-gray-600">
          {loading ? "We are verifying your credentials..." : (token ? error?.message : "Don't know where you got this link from, but it sure isn't valid.")}
        </p>
      </div>
    </div>
  )
}

export default AuthPage