import { AvatarUpload, SelectInput, TextInput } from "components/core";
import { useFormik } from "formik";
import { FC } from "react";
import { classNames, wrapClick } from "utils";
import { Genders, Nationalities, Titles } from "apollo/data";
import lodash from "lodash";
import { CustomerInfoSchema, ICreateRegularizationFormSchema } from "./schema";
import moment from "moment";

interface CustomerInfoFormProps {
  handleNext: (values: ICreateRegularizationFormSchema["customerInfo"]) => void;
  handlePrevious: () => void;
  initialValues: ICreateRegularizationFormSchema["customerInfo"];
  values: ICreateRegularizationFormSchema;
  handleCancel: () => void;
}

const CustomerInfoForm: FC<CustomerInfoFormProps> = ({
  initialValues,
  handleNext,
  handleCancel,
  handlePrevious,
}) => {
  const form = useFormik<ICreateRegularizationFormSchema["customerInfo"]>({
    initialValues,
    validationSchema: CustomerInfoSchema,
    onSubmit: (values) => {
      handleNext(values);
    },
    onReset: () => {
      handleCancel?.();
    },
  });

  return (
    <div className="flex-1 flex flex-col overflow-hidden">
      <div className="space-y-6 divide-y divide-gray-200 p-4 md:p-6 flex-1 overflow-y-auto">
        {/* {JSON.stringify(form.errors, null, 2)} */}
        <div>
          <span className="text-xs font-light">Customer Information</span>
          <div className="grid grid-cols-3 gap-6 mt-2">
            <div className="col-span-1">
              <SelectInput
                id="customerType"
                label="Customer Type"
                options={[
                  { label: "--- Select Customer Type ---", value: "" },
                  {
                    label: "Organization (Public/Private)",
                    value: "Organization",
                  },
                  { label: "Individual", value: "Individual" },
                ]}
                required={true}
                {...form}
              />
            </div>
          </div>
        </div>
        {form.values.customerType === "Individual" && (
          <>
            <div className="pt-6">
              <span className="text-xs font-light">Customer Information</span>
              <div className="grid grid-cols-6 gap-4 md:gap-6 mt-2">
                <div className="col-span-4">
                  <AvatarUpload
                    id="representative.profileImageUrl"
                    label="Photo"
                    {...form}
                  />
                </div>

                <div className="col-start-1 col-span-2">
                  <SelectInput
                    id="representative.title"
                    label=" Title"
                    placeholder="Customer Title"
                    options={[
                      { label: "--- Select Title ---", value: "" },
                      ...Titles,
                    ]}
                    required={true}
                    {...form}
                  />
                </div>

                <div className="col-span-4">
                  <TextInput
                    id="representative.fullName"
                    label="Full Name"
                    type="text"
                    required={true}
                    placeholder="e.g. Mensah Enoch Nana Nyankah"
                    {...form}
                  />
                </div>

                <div className="col-span-2">
                  <SelectInput
                    id="representative.nationality"
                    label="Nationality"
                    options={[
                      { label: "--- Select Nationality ---", value: "" },
                      ...lodash
                        .map(Nationalities, "nationality")
                        .map((nationality) => ({
                          label: nationality,
                          value: nationality,
                        })),
                    ]}
                    required={true}
                    {...form}
                  />
                </div>

                <div className="col-span-2">
                  <TextInput
                    id="representative.dateOfBirth"
                    label="Date of Birth"
                    type="date"
                    required={true}
                    max={moment().subtract(18, "years").format("YYYY-MM-DD")}
                    {...form}
                  />
                </div>

                <div className="col-span-2">
                  <SelectInput
                    id="representative.gender"
                    label="Gender"
                    options={[
                      { label: "--- Select Gender ---", value: "" },
                      ...Genders,
                    ]}
                    required={true}
                    {...form}
                  />
                </div>
              </div>
            </div>
            <div className="pt-6">
              <span className="text-xs font-light">Contact Information</span>
              <div className="grid grid-cols-3 gap-6 mt-2">
                <div className="col-span-1">
                  <TextInput
                    id="representative.phoneNumber"
                    label="Phone Number"
                    type="text"
                    placeholder="e.g. 0550123292"
                    maxLength={10}
                    required={true}
                    {...form}
                  />
                </div>

                <div className="col-span-2">
                  <TextInput
                    id="representative.emailAddress"
                    label="Email Address"
                    type="email"
                    placeholder="e.g. nyankahmensah@gmail.com"
                    {...form}
                  />
                </div>
              </div>
            </div>
          </>
        )}
        {form.values.customerType === "Organization" && (
          <>
            <div className="pt-6">
              <span className="text-xs font-light">
                Organization Information
              </span>
              <div className="grid grid-cols-3 gap-4 mt-2">
                <div className="col-span-3 md:col-span-2">
                  <TextInput
                    id="organization.name"
                    label="Full Name"
                    type="text"
                    required={true}
                    placeholder="e.g. Kofi And Sons Organization Limited"
                    {...form}
                  />
                </div>
              </div>
            </div>
            <div className="pt-6">
              <span className="text-xs font-light">
                Representative Information
              </span>
              <div className="grid grid-cols-6 gap-4 mt-2">
                <div className="col-span-4">
                  <AvatarUpload
                    id="representative.profileImageUrl"
                    label="Photo"
                    {...form}
                  />
                </div>

                <div className="col-start-1 col-span-2">
                  <SelectInput
                    id="representative.title"
                    label="Title"
                    placeholder="Representative's Title"
                    options={[
                      { label: "--- Select Title ---", value: "" },
                      ...Titles,
                    ]}
                    required={true}
                    {...form}
                  />
                </div>

                <div className="col-span-4">
                  <TextInput
                    id="representative.fullName"
                    label="Full Name"
                    type="text"
                    required={true}
                    placeholder="e.g. Mensah Enoch Nana Nyankah"
                    {...form}
                  />
                </div>

                <div className="col-span-2">
                  <SelectInput
                    id="representative.nationality"
                    label="Nationality"
                    placeholder="Nationality"
                    options={[
                      { label: "--- Select Nationality ---", value: "" },
                      ...lodash
                        .map(Nationalities, "nationality")
                        .map((nationality) => ({
                          label: nationality,
                          value: nationality,
                        })),
                    ]}
                    required={true}
                    {...form}
                  />
                </div>

                <div className="col-span-2">
                  <TextInput
                    id="representative.dateOfBirth"
                    label="Date of Birth"
                    type="date"
                    required={true}
                    max={moment().subtract(18, "years").format("YYYY-MM-DD")}
                    {...form}
                  />
                </div>

                <div className="col-span-2">
                  <SelectInput
                    id="representative.gender"
                    label="Gender"
                    options={[
                      { label: "--- Select Gender ---", value: "" },
                      ...Genders,
                    ]}
                    required={true}
                    {...form}
                  />
                </div>
              </div>
            </div>
            <div className="pt-6">
              <span className="text-xs font-light">
                Representative's Contact Information
              </span>
              <div className="grid grid-cols-3 gap-6 mt-2">
                <div className="col-span-1">
                  <TextInput
                    id="representative.phoneNumber"
                    label="Phone Number"
                    type="text"
                    placeholder="e.g. 0550123292"
                    maxLength={10}
                    required={true}
                    {...form}
                  />
                </div>

                <div className="col-span-2">
                  <TextInput
                    id="representative.emailAddress"
                    label="Email Address"
                    type="email"
                    placeholder="e.g. nyankahmensah@gmail.com"
                    {...form}
                  />
                </div>
              </div>
            </div>
          </>
        )}
      </div>

      <div className="bg-gray-50 dark:bg-gray-800 px-4 py-3 sm:py-4 sm:px-6 sm:flex sm:flex-row-reverse border-t border-gray-200">
        <button
          type="button"
          onClick={wrapClick(form.handleSubmit)}
          // disabled={!form.isValid}
          className={classNames(
            true ? "hover:bg-primary-700" : "cursor-not-allowed",
            "w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
          )}
        >
          Next
        </button>
        <button
          type="button"
          className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 dark:border-gray-600 shadow-sm px-4 py-2 bg-white dark:bg-gray-900 text-base font-medium text-gray-700 dark:text-gray-200 hover:bg-gray-50 hover:dark:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
          onClick={wrapClick(handlePrevious)}
        >
          Previous
        </button>
        <button
          type="button"
          className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 dark:border-gray-600 shadow-sm px-4 py-2 bg-white dark:bg-gray-900 text-base font-medium text-gray-700 dark:text-gray-200 hover:bg-gray-50 hover:dark:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
          onClick={wrapClick(form.resetForm)}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

export default CustomerInfoForm;
