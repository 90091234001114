import { TextInput, UploadArea } from "components/core"
import { ActivityPicker, DistrictPicker, GooglePlacesSelect, PremiseCategoryPicker, PremiseTypePicker, RegionPicker, SubActivityPicker } from "containers";
import { useFormik } from "formik"
import { FC, useEffect } from "react"
import { validators, wrapClick } from "utils";
import { ICreateRegularizationFormSchema, PropertyInfoSchema } from "./schema";
import { gql, useLazyQuery } from "@apollo/client";
import { MapView } from "components/cards";

interface PropertyInfoFormProps {
  handleNext: (values: ICreateRegularizationFormSchema["propertyInfo"]) => void
  handlePrevious: () => void;
  initialValues: ICreateRegularizationFormSchema["propertyInfo"];
  values: ICreateRegularizationFormSchema;
  handleCancel: () => void;
}

const RESOLVE_ADDRESS_INFO = gql`
  query ResolveGhanaPostAddress($address: String!) {
    address: resolveGhanaPostAddress(address: $address) {
      _id
      postCode
      district
      region
      area
      address
      street
      coordinates {
        longitude
        latitude
      }
    }
  }
`;

const PropertyInfoForm: FC<PropertyInfoFormProps> = ({ initialValues, handleNext, handlePrevious, handleCancel }) => {

  const form = useFormik<ICreateRegularizationFormSchema["propertyInfo"]>({
    initialValues,
    validationSchema: PropertyInfoSchema,
    onSubmit: (values) => {
      handleNext(values)
    },
    onReset: () => {
      handleCancel?.();
    }
  })
  
  const [resolveGhanaPostAddress] = useLazyQuery(RESOLVE_ADDRESS_INFO);

  useEffect(() => {
    if (form.values.ghanaPostAddress.match(validators.GhanaPostRegex)) {
      resolveGhanaPostAddress({
        variables: {
          address: form.values.ghanaPostAddress
        }
      })
        .then(({ data, error }) => {
          if (data?.address?._id) {
            if (data.address?.coordinates) {
              form.setFieldValue("geoLocation", {
                longitude: data.address.coordinates.longitude,
                latitude: data.address.coordinates.latitude,
              })
            }
            if (data.address?.street) {
              form.setFieldValue("streetName", data.address.street)
            }
            if (data.address?.area) {
              form.setFieldValue("community", data.address.area)
            }
          }
          else if (error) {
            form.setFieldTouched("ghanaPostAddress", true);
            form.setFieldError("ghanaPostAddress", error?.message);
          }
        })
        .catch(err => {
          form.setFieldTouched("ghanaPostAddress", true);
          form.setFieldError("ghanaPostAddress", "Invalid Ghana Post Address");
        })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.values.ghanaPostAddress])

  return (
    <div className="flex-1 flex flex-col overflow-hidden">
      <div className="space-y-6 divide-y divide-gray-200 p-4 md:p-6 flex-1 overflow-y-auto">
        <div className="">
          <span className="text-xs font-light">Owner Information</span>
          <div className="grid grid-cols-3 gap-4 md:gap-6 mt-2">
            <div className="col-span-3 md:col-span-2">
              <TextInput
                id="owner.fullName"
                label="Full Name"
                type="text"
                placeholder="e.g. Atta Adwoa"
                required={true}
                {...form}
              />
            </div>
            <div className="col-span-3 md:col-span-1">
              <TextInput
                id="owner.phoneNumber"
                label="Phone Number"
                type="text"
                placeholder="e.g. 0550123292"
                required={true}
                maxLength={10}
                {...form}
              />
            </div>
          </div>
        </div>
        <div className="pt-6">
          <span className="text-xs font-light">Property Information</span>
          <div className="grid grid-cols-6 gap-4 md:gap-6 mt-2">
            <div className="col-span-3 md:col-span-2">
              <RegionPicker
                id="region"
                label="Region"
                placeholder="Region Name"
                required={true}
                {...form}
                setFieldValue={(field: string, value: string) => {
                  form.setFieldValue("district", null);
                  form.setFieldValue(field, value);
                }}
              />
            </div>
            {form.values.region?._id && (
              <div className="col-span-3 md:col-span-2">
                <DistrictPicker
                  id="district"
                  label="District"
                  placeholder="District Name"
                  required={true}
                  filter={{ region: form.values.region?._id }}
                  {...form}
                  setFieldValue={(field: string, value: string) => {
                    form.setFieldValue(field, value);
                  }}
                />
              </div>
            )}
            <div className="col-span-2 col-start-1">
              <TextInput
                id="ghanaPostAddress"
                label="Ghana Post Address"
                type="text"
                maxLength={12}
                minLength={11}
                placeholder="eg. GH-2323-2323"
                required={true}
                {...form}
              />
            </div>
            <div className="col-span-4 md:col-span-2">
              <TextInput
                id="streetName"
                label="Street Name"
                type="text"
                placeholder="eg. Orange Street"
                required={true}
                {...form}
              />
            </div>
            <div className="col-span-2 md:col-span-2">
              <TextInput
                id="houseNumber"
                label="House Number"
                type="text"
                placeholder="eg. 67"
                required={true}
                {...form}
              />
            </div>
            <div className="col-span-6 md:col-span-2">
              <TextInput
                id="community"
                label="Community"
                type="text"
                placeholder="eg. Market Circle"
                required={true}
                {...form}
              />
            </div>
            <div className="col-span-6 md:col-span-4">
              <GooglePlacesSelect
                id="landmark"
                label="Closest Landmark"
                placeholder="eg. Under the house"
                required={true}
                {...form}
              />
            </div>
          </div>
        </div>
        <div className="pt-6">
          <span className="text-xs font-light">Premise Information</span>
          <div className="grid grid-cols-6 gap-4 md:gap-6 mt-2">
            <div className="col-start-1 col-span-3 md:col-span-2">
              <PremiseTypePicker
                id="premiseType"
                label="Premise Type"
                placeholder="e.g. Accomodation"
                required={true}
                {...form}
                setFieldValue={(field: string, value: string) => {
                  form.setFieldValue("premiseCategory", null);
                  form.setFieldValue(field, value);
                }}
              />
            </div>
            <div className="col-span-3 md:col-span-4">
              <PremiseCategoryPicker
                id="premiseCategory"
                label="Premise Category"
                placeholder="e.g. Hotel"
                required={true}
                disabled={!form.values.premiseType}
                {...form}
                filter={{
                  premiseType: form?.values?.premiseType?._id
                }}
              />
            </div>
            <div className="col-start-1 col-span-3 md:col-span-2">
              <ActivityPicker
                id="activity"
                label="Activity"
                placeholder="e.g. Hospitality"
                required={true}
                {...form}
                setFieldValue={(field: string, value: string) => {
                  form.setFieldValue("subActivity", null);
                  form.setFieldValue(field, value);
                }}
              />
            </div>
            <div className="col-span-3 md:col-span-4">
              <SubActivityPicker
                id="subActivity"
                label="Sub Activity"
                placeholder="e.g. Guest House"
                required={true}
                disabled={!form.values.activity}
                {...form}
                filter={{
                  activity: form?.values?.activity?._id
                }}
              />
            </div>
          </div>
        </div>
        <div className="pt-6">
          <span className="text-xs font-light">Site Plan Information</span>
          <div className="mt-2">
            <UploadArea
              id="sitePlanDocumentUrl"
              label="Upload Site Plan"
              accept={{
                "image/*": [".png", ".jpeg", ".jpg"],
                "application/pdf": [".pdf"]
              }}
              className="w-full h-96"
              {...form}
            />
          </div>
        </div>
        <div className="pt-6">
          <span className="text-xs font-light">Map Information</span>
          <div className="mt-2 w-full h-96 rounded-md overflow-hidden">
            <MapView
              longitude={form.values.geoLocation.longitude}
              latitude={form.values.geoLocation.latitude}
            />
          </div>
        </div>
      </div>

      <div className="bg-gray-50 px-4 py-3 sm:py-4 sm:px-6 flex flex-row-reverse border-t border-gray-200">
        <button
          type="button"
          onClick={wrapClick(form.handleSubmit)}
          className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 ml-3 sm:w-auto sm:text-sm"
        >
          Next
        </button>
        <button
          type="button"
          className="w-full inline-flex justify-center rounded-md border border-gray-300 dark:border-gray-600 shadow-sm px-4 py-2 bg-white dark:bg-gray-900 text-base font-medium text-gray-700 dark:text-gray-200 hover:bg-gray-50 hover:dark:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
          onClick={wrapClick(handlePrevious)}
        >
          Previous
        </button>
        <button
          type="button"
          className="hidden  w-full md:inline-flex justify-center rounded-md border border-gray-300 dark:border-gray-600 shadow-sm px-4 py-2 bg-white dark:bg-gray-900 text-base font-medium text-gray-700 dark:text-gray-200 hover:bg-gray-50 hover:dark:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
          onClick={wrapClick(form.resetForm)}
        >
          Cancel
        </button>
      </div>
    </div>
  )
}

export default PropertyInfoForm
