import { FC } from "react"
import { ICreateRegularizationFormSchema } from "./schema"
import _ from "lodash"
import { wrapImage } from "utils"

const BillingInfoSummary: FC<{ data: ICreateRegularizationFormSchema["billingInfo"] }> = ({ data }) => (
  <div className="border-2 border-dashed border-gray-300 dark:border-gray-600 rounded-md p-3 space-y-6 divide-y divide-gray-200">
    <div className=''>
      <span className='text-xs font-light'>Service Information</span>
      <div className='grid grid-cols-6 gap-4 md:gap-6 mt-2'>
        <div className="col-span-3 md:col-span-2">
          <span className="block text-sm font-light text-gray-700">
            Neighbourhood Class
          </span>
          <div className="mt-1 block w-full sm:text-sm">
            {data?.neighbourhoodClass ? _.startCase(data.neighbourhoodClass) : "N/A"}
          </div>
        </div>
        <div className="col-span-3 md:col-span-2">
          <span className="block text-sm font-light text-gray-700">
          Number of rooms
          </span>
          <div className="mt-1 block w-full sm:text-sm">
            {data?.numberOfRooms || "N/A"}
          </div>
        </div>
        <div className="col-span-3 md:col-span-2">
          <span className="block text-sm font-light text-gray-700">
          Number of months
          </span>
          <div className="mt-1 block w-full sm:text-sm">
            {data?.numberOfMonths || "N/A"}
          </div>
        </div>
      </div>
    </div>
    <div className='pt-6'>
      <span className='text-xs font-light'>Meter Information</span>
      <div className='grid grid-cols-3 gap-4 md:gap-6 mt-2'>
        <div className="col-span-2 md:col-span-1">
          <span className="block text-sm font-light text-gray-700">
            Meter Number
          </span>
          <div className="mt-1 block w-full sm:text-sm">
            {data?.meterNumber || "N/A"}
          </div>
        </div>
        <div className="col-span-3 md:col-span-2">
          <span className="block text-sm font-light text-gray-700">
            Meter Image
          </span>
          <div className="mt-1 block sm:text-sm">
            {wrapImage(
              <img
                src={data?.meterImageUrl}
                alt={data?.meterNumber}
                className="w-full  h-64 object-cover object-top text-xs"
              />
            )}
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default BillingInfoSummary