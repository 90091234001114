import { gql } from "@apollo/client";
export const GET_REGULARIZATION_INITIATIONS = gql`
   query GetRegularizationInitiations(
    $page: Int
    $pageSize: Int
    $search: String
    $searchFields: [String!]
    $sort: String
    $region: ID
    $district: ID
    $status: RegularizationInitiationStatus
  ) {
    rows: getRegularizationInitiations(
      page: $page
      pageSize: $pageSize
      search: $search
      searchFields: $searchFields
      sort: $sort
      region: $region
      district: $district
      status: $status
    ) {
      _id
      code
      status
      createdAt
      updatedAt
      initiations {
        _id
        phoneNumber
        ghanaPostAddress
        meterPhase
        status
        statusReason
      }
      region {
        _id
        code
        name
      }
      meta {
        totalFailedInitiations
        totalInitiations
        totalSuccessInitiations
      }
      createdBy {
        _id
        code
        lastName
        firstName
        emailAddress
        phoneNumber
      }
      district {
        _id
        code
        name
      }
    }
    count: getRegularizationInitiationsCount(
      search: $search
      searchFields: $searchFields
      region: $region
      district: $district
      status: $status
    )
  }
`;



export const GET_SERVICE_REGULARIZATIONS = gql`
  query GetRegularizations(
    $page: Int
    $pageSize: Int
    $sort: String
    $search: String
    $searchFields: [String!]
    $status: RegularizationStatus
    $region: ID
    $district: ID
    $fromDate: Date
  $toDate: Date
  $dateField: String
  $month: PositiveInt
  $year: PositiveInt
  ) {
    rows: getRegularizations(
      page: $page
      pageSize: $pageSize
      sort: $sort
      search: $search
      searchFields: $searchFields
      region: $region
      district: $district
       fromDate: $fromDate
    toDate: $toDate
    dateField: $dateField
    month: $month
    year: $year
    status: $status
      
    ) {
      _id
      code
      status
      region {
        _id
        code
        name
      }
      district {
        _id
        code
        name
      }
      createdAt
      updatedAt
       createdBy {
        _id
        code
        lastName
        firstName
        emailAddress
        phoneNumber
      }
      customerData {
        customerType

        organization {
          name
        }
        representative {
          fullName
          phoneNumber
        }
      }
      billing {
        neighbourhoodClass
      }
      property {
        community
        ghanaPostAddress
      }
      service {
        serviceClass
      }
    }
    count: getRegularizationsCount(
      search: $search
      searchFields: $searchFields
      region: $region
      district: $district
      fromDate: $fromDate
    toDate: $toDate
    dateField: $dateField
    month: $month
    year: $year
    status: $status

    )
  }
`;

// export const useServiceRegularizations = (variables: any) => {
//   const { data, ...rest } = useQuery(GET_SERVICE_REGULARIZATIONS, {
//     variables,
//     notifyOnNetworkStatusChange: true,
//   });
//   const { data: countData } = useQuery(GET_SERVICE_REGULARIZATIONS_COUNT, {
//     variables,
//   });
//   const serviceRegularizations = data?.rows || [];
//   const count = countData?.count || 0;
//   return { serviceRegularizations, count, ...rest };
// };
