import { Disclosure } from "@headlessui/react";
import { ChevronUpIcon } from "@heroicons/react/24/outline";
import { SelectInput } from "components/core";
import { useFormik } from "formik";
import lodash from "lodash";
import { FC, useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { classNames, wrapClick } from "utils";
import { read, utils } from "xlsx";

import {
  ICreateRegularizationsInitiationFormSchema,
  InitiationsInfoFormSchema,
} from "./schema";
import _ from "lodash";
const FORMAT = {
  customerType: "Customer Type",
  organizationName: "Organization Name",
  taxIdentificationNumber: "Tax Identification Number",
  organizationRegistrationNumber: "Organization Registration Number",
  organizationRegistrationDate: "Organization Registration Date",
  organizationRegistrationDocumentUrl: "Organization Registration Document URL",
  certificateOfIncorporationDocumentUrl:
    "Certificate of Incorporation Document URL",
  title: "Title",
  representativeName: "Representative Name",
  nationality: "Nationality",
  dateOfBirth: "Date of Birth",
  gender: "Gender",
  phoneNumber: "Phone Number",
  emailAddress: "Email Address",
  profileImageUrl: "Profile Image URL",
  hasGhanaCard: "Has Ghana Card",
  ghanaCardNumber: "Ghana Card Number",
  ghanaCardIssueDate: "Ghana Card Issue Date",
  ghanaCardExpiryDate: "Ghana Card Expiry Date",
  ghanaCardFrontImageUrl: "Ghana Card Front Image URL",
  ghanaCardBackImageUrl: "Ghana Card Back Image URL",
  identityCardType: "Identity Card Type",
  identityCardNumber: "Identity Card Number",
  identityCardIssueDate: "Identity Card Issue Date",
  identityCardExpiryDate: "Identity Card Expiry Date",
  identityCardFrontImageUrl: "Identity Card Front Image URL",
  identityCardBackImageUrl: "Identity Card Back Image URL",
  propertyOwnerName: "Property Owner Name",
  propertyOwnerPhoneNumber: "Property Owner Phone Number",
  ghanaPostAddress: "Ghana Post Address",
  community: "Community",
  streetName: "Street Name",
  houseNumber: "House Number",
  structureNumber: "Structure Number",
  landmark: "Landmark",
  premiseTypeCode: "Premise Type Code",
  premiseCategoryCode: "Premise Category Code",
  activityCode: "Activity Code",
  subActivityCode: "Sub Activity Code",
  longitude: "Longitude",
  latitude: "Latitude",
  sitePlanDocumentUrl: "Site Plan Document URL",
  serviceType: "Service Type",
  serviceClass: "Service Class",
  meterPhase: "Meter Phase",
  energyCertificateIssuerId: "Energy Certificate Issuer ID",
  energyCertificateIssuerName: "Energy Certificate Issuer Name",
  energyCertificateIssuerPhone: "Energy Certificate Issuer Phone",
  energyCertificateNumber: "Energy Certificate Number",
  energyCertificateDocumentUrl: "Energy Certificate Document URL",
  neighbourhoodClass: "Neighbourhood Class",
  numberOfRooms: "Number of Rooms",
  numberOfMonths: "Number of Months",
  meterNumber: "Meter Number",
  meterImageUrl: "Meter Image URL",
};
export interface CreateRegularizationsInitiationFormProps {
  handleNext: (
    values: ICreateRegularizationsInitiationFormSchema["initiationsInfo"]
  ) => void;
  handlePrevious: () => void;
  initialValues: ICreateRegularizationsInitiationFormSchema["initiationsInfo"];
  values: ICreateRegularizationsInitiationFormSchema;
  handleCancel: () => void;
}

const CreateRegularizationsInitiationForm: FC<
  CreateRegularizationsInitiationFormProps
> = ({ initialValues, handleNext, handlePrevious, handleCancel }) => {
  // const [isLoading, setIsLoading] = useState(false)

  const [columns, setColumns] = useState<string[]>([]);
  const [data, setData] = useState<any[]>([]);

  const form = useFormik<
    ICreateRegularizationsInitiationFormSchema["initiationsInfo"]
  >({
    initialValues,
    validationSchema: InitiationsInfoFormSchema,
    onSubmit: (values) => {
      handleNext(values);
    },
    onReset: () => {
      handleCancel?.();
    },
  });

  const selectForm = useFormik<{
    customerType: string;
    organizationName: string;
    taxIdentificationNumber: string;
    organizationRegistrationNumber: string;
    organizationRegistrationDate: string;
    organizationRegistrationDocumentUrl: string;
    certificateOfIncorporationDocumentUrl: string;
    title: string;
    representativeName: string;
    nationality: string;
    dateOfBirth: string;
    gender: string;
    phoneNumber: string;
    emailAddress: string;
    profileImageUrl: string;
    hasGhanaCard: string;
    ghanaCardNumber: string;
    ghanaCardIssueDate: string;
    ghanaCardExpiryDate: string;
    ghanaCardFrontImageUrl: string;
    ghanaCardBackImageUrl: string;
    identityCardType: string;
    identityCardNumber: string;
    identityCardIssueDate: string;
    identityCardExpiryDate: string;
    identityCardFrontImageUrl: string;
    identityCardBackImageUrl: string;
    propertyOwnerName: string;
    propertyOwnerPhoneNumber: string;
    ghanaPostAddress: string;
    community: string;
    streetName: string;
    houseNumber: string;
    structureNumber: string;
    landmark: string;
    premiseTypeCode: string;
    premiseCategoryCode: string;
    activityCode: string;
    subActivityCode: string;
    longitude: string;
    latitude: string;
    sitePlanDocumentUrl: string;
    serviceType: string;
    serviceClass: string;
    meterPhase: string;
    energyCertificateIssuerId: string;
    energyCertificateIssuerName: string;
    energyCertificateIssuerPhone: string;
    energyCertificateNumber: string;
    energyCertificateDocumentUrl: string;
    neighbourhoodClass: string;
    numberOfRooms: string;
    numberOfMonths: string;
    meterNumber: string;
    meterImageUrl: string;
  }>({
    onSubmit: (values) => {
      const initiations = data.map((el) => {
        const item = lodash.keys(values)?.map((column) => {
          const obj: any = {};
          const keyValue = lodash.get(values, column);
          obj[column] = el[keyValue];
          return obj;
        });
        return lodash.merge({}, ...item);
      });
      form.setFieldValue("initiations", initiations);
    },
    initialValues: {
      customerType: "Customer Type",
      organizationName: "Organization Name",
      taxIdentificationNumber: "Tax Identification Number",
      organizationRegistrationNumber: "Organization Registration Number",
      organizationRegistrationDate: "Organization Registration Date",
      organizationRegistrationDocumentUrl:
        "Organization Registration Document URL",
      certificateOfIncorporationDocumentUrl:
        "Certificate of Incorporation Document URL",
      title: "Title",
      representativeName: "Representative Name",
      nationality: "Nationality",
      dateOfBirth: "Date of Birth",
      gender: "Gender",
      phoneNumber: "Phone Number",
      emailAddress: "Email Address",
      profileImageUrl: "Profile Image URL",
      hasGhanaCard: "Has Ghana Card",
      ghanaCardNumber: "Ghana Card Number",
      ghanaCardIssueDate: "Ghana Card Issue Date",
      ghanaCardExpiryDate: "Ghana Card Expiry Date",
      ghanaCardFrontImageUrl: "Ghana Card Front Image URL",
      ghanaCardBackImageUrl: "Ghana Card Back Image URL",
      identityCardType: "Identity Card Type",
      identityCardNumber: "Identity Card Number",
      identityCardIssueDate: "Identity Card Issue Date",
      identityCardExpiryDate: "Identity Card Expiry Date",
      identityCardFrontImageUrl: "Identity Card Front Image URL",
      identityCardBackImageUrl: "Identity Card Back Image URL",
      propertyOwnerName: "Property Owner Name",
      propertyOwnerPhoneNumber: "Property Owner Phone Number",
      ghanaPostAddress: "Ghana Post Address",
      community: "Community",
      streetName: "Street Name",
      houseNumber: "House Number",
      structureNumber: "Structure Number",
      landmark: "Landmark",
      premiseTypeCode: "Premise Type Code",
      premiseCategoryCode: "Premise Category Code",
      activityCode: "Activity Code",
      subActivityCode: "Sub Activity Code",
      longitude: "Longitude",
      latitude: "Latitude",
      sitePlanDocumentUrl: "Site Plan Document URL",
      serviceType: "Service Type",
      serviceClass: "Service Class",
      meterPhase: "Meter Phase",
      energyCertificateIssuerId: "Energy Certificate Issuer ID",
      energyCertificateIssuerName: "Energy Certificate Issuer Name",
      energyCertificateIssuerPhone: "Energy Certificate Issuer Phone",
      energyCertificateNumber: "Energy Certificate Number",
      energyCertificateDocumentUrl: "Energy Certificate Document URL",
      neighbourhoodClass: "Neighbourhood Class",
      numberOfRooms: "Number of Rooms",
      numberOfMonths: "Number of Months",
      meterNumber: "Meter Number",
      meterImageUrl: "Meter Image URL",
    },
    onReset: (values) => {},
  });

  const processData = (dataString: any) => {
    // console.log(dataString)
    const dataStringLines = dataString.split(/\r\n|\n/);
    const headers = dataStringLines[0].split(
      /,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/
    );
    // console.log("HEADERS", dataStringLines)
    const list = [];
    for (let i = 1; i < dataStringLines.length; i++) {
      const row = dataStringLines[i].split(
        /,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/
      );
      if (headers && row.length === headers.length) {
        const obj: any = {};
        for (let j = 0; j < headers.length; j++) {
          let d = row[j];
          if (d.length > 0) {
            if (d[0] === '"') d = d.substring(1, d.length - 1);
            if (d[d.length - 1] === '"') d = d.substring(d.length - 2, 1);
          }
          if (headers[j]) {
            obj[headers[j]] = d;
          }
        }

        // remove the blank rows
        if (Object.values(obj).filter((x) => x).length > 0) {
          list.push(obj);
        }
      }
    }
    setData(list);
    setColumns(headers);
  };

  const onDrop = useCallback((acceptedFiles: any) => {
    // Do something with the files
    const file = acceptedFiles[0];
    // console.log("---FILE---", file)
    // setMembers(file)
    const reader = new FileReader();
    reader.onload = (evt) => {
      /* Parse data */
      const bstr = evt?.target?.result;
      const wb = read(bstr, { type: "binary" });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      const data = utils.sheet_to_csv(ws, {});
      processData(data);
    };
    reader.readAsBinaryString(file);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: false,
    // accept: ".csv, .xlsx, .xls, .json",
    accept: {
      "text/csv": [".csv"],
      "text/xlsx": [".xlsx"],
      "text/xls": [".xls"],
      "text/json": [".json"],
    },
  });
  useEffect(() => {
    console.log(form.errors.initiations);
  }, [form.errors.initiations]);

  return (
    <div className='flex-1 flex flex-col overflow-x-scroll'>
      {form.errors.initiations && (
        <Disclosure as='div' className='px-6 pt-6'>
          {({ open }) => (
            <>
              <Disclosure.Button
                className={classNames(
                  "flex w-full justify-between bg-red-100 px-4 py-2 text-left text-sm font-medium text-red-900 hover:bg-red-200 focus:outline-none focus-visible:ring focus-visible:ring-red-500 focus-visible:ring-opacity-75",
                  open ? "rounded-t-lg" : "mb-2 rounded-lg"
                )}
              >
                <span>Errors Detected</span>
                <ChevronUpIcon
                  className={`${
                    !open ? "rotate-180 transform" : ""
                  } h-5 w-5 text-red-500`}
                />
              </Disclosure.Button>
              <Disclosure.Panel className='px-4 pt-4 pb-2 mb-2 text-sm text-gray-500 rounded-b-lg bg-red-100'>
                <div>
                  {lodash.isArray(form.errors?.initiations) ? (
                    form?.errors?.initiations?.map((row, rowIndex) => (
                      <div className='text-red-700'>
                        {row && (
                          <span className='font-bold'>Row: {rowIndex + 1}</span>
                        )}
                        {lodash.isString(row) ? (
                          <span>{row}</span>
                        ) : (
                          <div className='flex flex-col'>
                            {Object.keys(FORMAT)?.map((key, idx) =>
                              _.get(row, key) ? (
                                <li>{_.get(row, key)}</li>
                              ) : null
                            )}
                          </div>
                        )}
                      </div>
                    ))
                  ) : (
                    <span className='font-bold italic'>
                      {form?.errors?.initiations}
                    </span>
                  )}
                </div>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      )}

      <div className='p-6 inline-block align-bottom bg-white text-left h-[85vh] overflow-y-scroll transform transition-all'>
        {!data?.length ? (
          <div className='h-full'>
            <button
              type='button'
              {...getRootProps()}
              className='relative block w-full h-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
            >
              <svg
                className='mx-auto h-12 w-12 text-gray-400'
                stroke='currentColor'
                fill='none'
                viewBox='0 0 48 48'
                aria-hidden='true'
              >
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeWidth={2}
                  d='M8 14v20c0 4.418 7.163 8 16 8 1.381 0 2.721-.087 4-.252M8 14c0 4.418 7.163 8 16 8s16-3.582 16-8M8 14c0-4.418 7.163-8 16-8s16 3.582 16 8m0 0v14m0-4c0 4.418-7.163 8-16 8S8 28.418 8 24m32 10v6m0 0v6m0-6h6m-6 0h-6'
                />
              </svg>
              <h3 className='mt-2 text-sm font-medium text-gray-900'>
                No file selected
              </h3>
              <p className='mt-1 text-sm text-gray-500'>
                Kindly select a file by clicking the button above.
              </p>
              <input
                {...getInputProps()}
                id='members'
                name='members'
                type='file'
                className='sr-only'
              />
            </button>
          </div>
        ) : (
          <>
            <div className='w-full  border border-primary-400 rounded-md bg-primary-50 border-dashed my-2 py-4 sticky left-0'>
              <div className='flex justify-end px-4'>
                <button
                  {...getRootProps()}
                  type='button'
                  className='w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-gray-600 text-base font-medium text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 sm:ml-3 sm:w-auto sm:text-sm'
                >
                  Upload new file
                  <input
                    {...getInputProps()}
                    id='members'
                    name='members'
                    type='file'
                    className='sr-only'
                  />
                </button>
                <button
                  onClick={wrapClick(selectForm.handleSubmit)}
                  className='w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm'
                >
                  Show preview
                </button>
              </div>

              <div className='w-full flex justify-between p-3 items-end flex-1'>
                <div className='grid grid-cols-4 gap-4 w-full'>
                  {Object.keys(FORMAT).map((key, idx) => {
                    console.log(key, idx);
                    console.log(_.get(FORMAT, key));
                    return (
                      <div>
                        <SelectInput
                          label={_.get(FORMAT, key)}
                          defaultValue={_.get(FORMAT, key)}
                          id={key}
                          {...selectForm}
                          options={columns
                            ?.filter((_c: string) => _c.length > 0)
                            ?.map((column: any, idx: number) => ({
                              label: column,
                              value: column,
                            }))}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            {form?.values?.initiations && (
              <table className='min-w-full divide-y divide-gray-200 overflow-x-auto border'>
                <thead className='bg-gray-50 sticky top-0 z-10'>
                  <tr>
                    {["#", ...Object.values(FORMAT)]?.map(
                      (column: any, idx: number) => (
                        <th
                          scope='col'
                          className={classNames(
                            idx === 0 ? "sticky left-0 bg-gray-50" : "",
                            "px-6 py-3 mt-0 text-left text-xs font-medium whitespace-nowrap text-gray-500 uppercase tracking-wider"
                          )}
                        >
                          {column}
                        </th>
                      )
                    )}
                  </tr>
                </thead>
                <tbody className='bg-white divide-y divide-gray-200'>
                  {form?.values?.initiations?.map((member: any, key) => {
                    return (
                      <tr key={key}>
                        <td className='sticky left-0 bg-white px-6 py-4 whitespace-nowrap text-sm text-gray-500'>
                          {key + 1}
                        </td>
                        {Object.keys(FORMAT)?.map(
                          (column: any, idx: number) => (
                            <th
                              scope='col'
                              className={classNames(
                                idx === 0 ? "sticky left-0 bg-gray-50" : "",
                                "px-6 py-3 mt-0 text-left text-xs font-medium whitespace-nowrap text-gray-500 uppercase tracking-wider"
                              )}
                            >
                              {lodash.get(member, column, "N/A") || "N/A"}
                            </th>
                          )
                        )}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            )}
          </>
        )}
      </div>

      <div className='bg-gray-50 dark:bg-gray-800 px-4 py-3 sm:py-4 sm:px-6 sm:flex sm:flex-row-reverse border-t border-gray-200'>
        <button
          type='button'
          onClick={wrapClick(form.handleSubmit)}
          className='w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm'
        >
          Next
        </button>
        <button
          type='button'
          onClick={wrapClick(handlePrevious)}
          className='mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 dark:border-gray-600 shadow-sm px-4 py-2 bg-white dark:bg-gray-900 text-base font-medium text-gray-700 dark:text-gray-200 hover:bg-gray-50 hover:dark:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm'
        >
          Previous
        </button>
        <button
          type='button'
          className='mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 dark:border-gray-600 shadow-sm px-4 py-2 bg-white dark:bg-gray-900 text-base font-medium text-gray-700 dark:text-gray-200 hover:bg-gray-50 hover:dark:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm'
          onClick={wrapClick(form.resetForm)}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

export default CreateRegularizationsInitiationForm;
