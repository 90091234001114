import { Outlet, Route } from "react-location";
import {
  CustomersPage,
  DashboardPage,
  ForgotPasswordPage,
  SigninPage,
  AuthPage,
  ServiceRegularizationsPage,
  CreateRegularizationPage,
} from "pages";
import {
  ChartBarSquareIcon,
  UserCircleIcon,
  UsersIcon,
} from "@heroicons/react/24/outline";
import { UserPermission } from "apollo/cache/auth";

export type RouteProps = Omit<Route, "children"> & {
  withPermissions?: UserPermission[];
  navigation?: boolean;
  sidebar?: { label: string; icon: any };
  children?: RouteProps[];
};

const routes: RouteProps[] = [
  {
    path: "/",
    element: <DashboardPage />,
    sidebar: {
      label: "Dashboard",
      icon: ChartBarSquareIcon,
    },
    meta: {
      layout: "App",
      breadcrumb: () => "Dashboard",
      section: "General",
    },
  },
  {
    path: "service-points",
    element: <CustomersPage />,
    sidebar: {
      label: "Service Points",
      icon: UsersIcon,
    },
    meta: {
      layout: "App",
      breadcrumb: () => "Customers",
      section: "Structure",
    },
    withPermissions: ["*:*", "customers:*", "customers:manage"],
  },
  {
    path: "service-regularizations",
    element: <Outlet />,
    sidebar: {
      label: "Regularizations",
      icon: UserCircleIcon,
    },
    meta: {
      layout: "App",
      breadcrumb: () => "Regularizations",
      section: "Customer Relation",
    },
    children: [
      {
        path: "/",
        element: <ServiceRegularizationsPage />,
        meta: {
          layout: "App",
          section: "Customer Relation",
        },
      },
          {
        path: "new",
        element: <CreateRegularizationPage />,
        meta: {
          layout: "App",
          breadcrumb: () => "New Regularization",
          section: "Customer Relation",
        },
      },
      // {
      //   path: ":serviceRegularization",
      //   element: <ServiceRegularizationPage />,
      //   meta: {
      //     layout: "App",
      //     breadcrumb: () => "Service Regularization",
      //     section: "Faults And Complaints",
      //   },
      // },
    ],
    //TODO: Verify and enable permisions
    withPermissions: ["*:*", "service-requests:*", "service-requests:manage"],
  },
  {
    path: "forgot-password",
    element: <ForgotPasswordPage />,
    meta: {
      layout: "Auth",
      breadcrumb: () => "Forgot Password",
    },
  },
  {
    path: "signin",
    element: <SigninPage />,
    meta: {
      layout: "Auth",
      breadcrumb: () => "Signin",
    },
  },
  {
    path: "auth",
    element: <AuthPage />,
    meta: {
      layout: "Auth",
      breadcrumb: () => "Auth",
    },
  },
];

export default routes;
