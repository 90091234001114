import MetersInfoForm from "./initiations-info-form";
import MetersInfoSummary from "./initiations-info-summary";
import RegularizationsInfoForm from "./regularizations-info-form";
import RegularizationsInfoSummary from "./regularizations-info-summary";

const formSteps = [
  {
    name: "Regularizations Info",
    description: "Information about regularizations",
    accessor: "regularizationsInfo",
    FormComponent: RegularizationsInfoForm,
    SummaryComponent: RegularizationsInfoSummary,
  },
  {
    name: "Upload Data",
    description: "Upload file to begin initiating regularizations",
    accessor: "initiationsInfo",
    FormComponent: MetersInfoForm,
    SummaryComponent: MetersInfoSummary,
  },
];

export default formSteps;
