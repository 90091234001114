import { useQuery, useReactiveVar } from "@apollo/client";
import {
  Avatar,
  OfficeCalendarHeader,
  RegularizationsInitiationsTable,
  SelectInput,
  Shimmers,
  TableComponent,
} from "components";
import _ from "lodash";
import moment from "moment";
import { FC, useEffect, useMemo } from "react";
import { Link, useNavigate, useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import {
  classNames,
  wrapClick,
  useUrlState,
  useTableData,
  wrapOnchange,
} from "utils";
import config from "config";
import ViewBlockContainer from "./view";
import { currentConfigVar } from "apollo/cache/config";
import ActionButton, { Action } from "components/buttons/action-button";
import { PlusIcon } from "@heroicons/react/24/outline";
import { Menu } from "@headlessui/react";
import BulkCreateRegularizationsContainer from "./create-bulk";
import {
  GET_REGULARIZATION_INITIATIONS,
  GET_SERVICE_REGULARIZATIONS,
} from "./hooks";
import ViewRegularizationInitiationContainer from "./view-bulk";

const ServiceRequestStatuses = [
  "Pending",
  "Confirmed",
  "Rejected",
  "ApplicationFeeGenerated",
  "ApplicationFeePaid",
  "InspectionInitiated",
  "InspectionCompleted",
  "InspectionFailed",
  "ContractGenerated",
  "ContractSigned",
  "ConnectionFeeGenerated",
  "ConnectionFeePaid",
  "InstallationInitiated",
  "InstallationCompleted",
  "InstallationFailed",
  "Completed",
];

const ServiceRegularizationsPage: FC = () => {
  const { dateFormat } = useReactiveVar(currentConfigVar);

  const dispatchAction =
    (id: string, action: Exclude<Action, "expand" | "goto" | "clone">) =>
      () => {
        navigate({
          search: (old) => ({
            ...old,
            id,
            modal: action,
          }),
        });
      };

  const searchParams = useSearch<LocationGenerics>();
  const navigate = useNavigate<LocationGenerics>();
  const [modal, setModal] = useUrlState("modal");
  const [regularizationsKind] = useUrlState("regularizationsKind");

  const filter = useMemo(
    () => ({
      page: searchParams.page || 1,
      pageSize: searchParams.pageSize || 12,
      search: (searchParams.search || "").toString(),
      searchFields: [
        "code",
        "customerData.representative.fullName",
        "customerData.representative.phoneNumber",
      ],
      sort: searchParams.sort || "",
      region: searchParams.region || undefined,
      district: searchParams.district || undefined,
      fromDate: searchParams.fromDate || undefined,
      toDate: searchParams.toDate || undefined,
      status: searchParams.status || undefined,
    }),
    [searchParams]
  );

  const initiationsFilter = useMemo(
    () => ({
      page: searchParams.page || 1,
      pageSize: searchParams.pageSize || 12,
      search: (searchParams.search || "").toString(),
      searchFields: ["code"],
      sort: searchParams.sort || "",
      region: searchParams.region || undefined,
      district: searchParams.district || undefined,
      status: searchParams?.initiationStatus || undefined,
      fromDate: searchParams.fromDate || undefined,
      toDate: searchParams.toDate || undefined,
    }),
    [searchParams]
  );

  const [status, setStatus] = useUrlState("status");

  const { data, loading, networkStatus, refetch } = useQuery(
    searchParams?.regularizationsKind === "BulkInitiation"
      ? GET_REGULARIZATION_INITIATIONS
      : GET_SERVICE_REGULARIZATIONS,
    {
      variables:
        searchParams?.regularizationsKind === "BulkInitiation"
          ? initiationsFilter
          : filter,
      notifyOnNetworkStatusChange: true,
      onCompleted: () => {
        // refetchSummary?.();
      },
    }
  );
  const records = useTableData(data || {});

  useEffect(() => {
    navigate({
      search: (old) => ({
        ...old,
        ...(!old?.page ? { page: config.constants.page } : {}),
        ...(!old?.pageSize ? { pageSize: config.constants.pageSize } : {}),
        ...(!old?.search ? { search: "" } : {}),
        ...(!old?.sort ? { sort: "-createdAt" } : {}),
      }),
    });
  }, [navigate]);
  const onSearch = (searchValue: string, searchField: string) => {
    refetch({
      page: searchParams.page || 1,
      pageSize: searchParams.pageSize || 12,
      search: String(searchValue),
      searchFields: searchField ? [searchField] : filter.searchFields,
      sort: searchParams.sort || "",
      region: searchParams.region || undefined,
      district: searchParams.district || undefined,
      fromDate: searchParams.fromDate || undefined,
      toDate: searchParams.toDate || undefined,
      status: searchParams.status || undefined,
    });
  };
  const onInitiationsSearch = (searchValue: string, searchField: string) => {
    refetch({
      page: searchParams.page || 1,
      pageSize: searchParams.pageSize || 12,
      search: String(searchValue),
      searchFields: searchField ? [searchField] : initiationsFilter.searchFields,
      sort: searchParams.sort || "",
      region: searchParams.region || undefined,
      district: searchParams.district || undefined,
      status: searchParams?.initiationStatus || undefined,
      fromDate: searchParams.fromDate || undefined,
      toDate: searchParams.toDate || undefined,
    });
  };

  return (
    <main className='flex-1 flex flex-col overflow-hidden bg-gray-50'>
      <OfficeCalendarHeader
        views={["all-time", "day", "month", "custom"]}
        defaultView='all-time'
        renderActions={() => (
          <>
            <Menu as='div' className='relative inline-block text-left'>
              <Menu.Button className=' inline-flex items-center rounded-md border border-transparent bg-primary-600 px-3 py-2.5 text-sm leading-4 text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2'>
                <PlusIcon className='-ml-0.5 mr-2 h-4 w-4' aria-hidden='true' />
                Create New Regularization
              </Menu.Button>
              <Menu.Items className='absolute z-50 mt-2 w-full origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'>
                <Menu.Item>
                  {({ active }) => (
                    <button
                      onClick={wrapClick(() => navigate({ to: "./new" }))}
                      className={`${active ? "bg-primary-600 text-white" : "text-gray-900"
                        } group flex items-center w-full rounded-md px-2 py-2 text-sm`}
                    >
                      Single
                    </button>
                  )}
                </Menu.Item>
                <Menu.Item>
                  {({ active }) => (
                    <button
                      onClick={wrapClick(() => setModal("create-bulk"))}
                      className={`${active ? "bg-primary-600 text-white" : "text-gray-900"
                        } group flex items-center w-full rounded-md px-2 py-2 text-sm`}
                    >
                      Bulk
                    </button>
                  )}
                </Menu.Item>
              </Menu.Items>
            </Menu>
          </>
        )}
      />

      <div className='block'>
        <div className='border-b border-gray-200 bg-white px-6'>
          <nav
            className='-mb-px flex space-x-4 items-center overflow-x-scroll no-scrollbar'
            aria-label='Tabs'
          >
            <Link
              search={(old) => ({
                ...old,
                regularizationsKind: undefined,
                page: config.constants.page,
                pageSize: config.constants.pageSize,
              })}
              className={classNames(
                !regularizationsKind
                  ? "border-primary-500 text-primary-600"
                  : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200",
                "whitespace-nowrap flex py-4 px-1 border-b-2 font-medium text-sm"
              )}
              aria-current={!regularizationsKind ? "page" : undefined}
            >
              All Regularizations
              {/* <span
                className={classNames(
                  !regularizationKind
                    ? "bg-primary-100 text-primary-600"
                    : "bg-gray-100 text-gray-900",
                  "hidden ml-3 py-0.5 px-2.5 rounded-full text-xs font-medium md:inline-block"
                )}
              >
                {numeral(
                  _.sum(
                    _.values(
                      _.omit(
                        dataSummary?.getReplacementServiceOrderSummary,
                        "__typename"
                      )
                    )
                  )
                ).format("0,0")}
              </span> */}
            </Link>
            <Link
              search={(old) => ({
                ...old,
                regularizationsKind: "BulkInitiation",
                page: config.constants.page,
                pageSize: config.constants.pageSize,
              })}
              className={classNames(
                regularizationsKind === "BulkInitiation"
                  ? "border-primary-500 text-primary-600"
                  : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200",
                "whitespace-nowrap flex py-4 px-1 border-b-2 font-medium text-sm"
              )}
              aria-current={
                regularizationsKind === "BulkInitiation" ? "page" : undefined
              }
            >
              Bulk Uploads
              {/* <span
                className={classNames(
                  !regularizationKind
                    ? "bg-primary-100 text-primary-600"
                    : "bg-gray-100 text-gray-900",
                  "hidden ml-3 py-0.5 px-2.5 rounded-full text-xs font-medium md:inline-block"
                )}
              >
                {numeral(
                  _.sum(
                    _.values(
                      _.omit(
                        dataSummary?.getReplacementServiceOrderSummary,
                        "__typename"
                      )
                    )
                  )
                ).format("0,0")}
              </span> */}
            </Link>
          </nav>
        </div>
      </div>

      <div className='flex flex-1 overflow-y-auto'>
        <div className='flex-1 min-h-full mx-auto min-w-0  py-4 sm:py-6 overflow-hidden overflow-y-auto light flex'>
          {searchParams?.regularizationsKind === "BulkInitiation" ? (
            <RegularizationsInitiationsTable
              refetch={refetch}
              onSearch={onInitiationsSearch}
              isRefetching={loading && networkStatus === 4}
              loading={loading && ![4, 6].includes(networkStatus)}
              data={records}
              dispatchAction={dispatchAction}
            />
          ) : (
            <TableComponent
              title={"regularizations"}
              refetch={refetch}
              isRefetching={loading && networkStatus === 4}
              loading={loading && ![4, 6].includes(networkStatus)}
              data={records}
              hasSearch={true}
              onSearchSubmit={onSearch}
              // searchOptions={[
              //   {
              //     label: "Code",
              //     value: "code",
              //   },
              //   {
              //     label: "Representative Name",
              //     value: "customerData.representative.fullName",
              //   },
              //   {
              //     label: "Representative Phone Number",
              //     value: "customerData.representative.phoneNumber",
              //   },
              // ]}
              renderHeaderItems={() => (
                <div className='mt-4 sm:mt-0 sm:ml-16 sm:flex space-x-2'>
                  <SelectInput
                    id='status'
                    label=''
                    handleBlur={() => { }}
                    handleChange={wrapOnchange(setStatus)}
                    options={[
                      { label: "--- Select Status ---", value: "" },
                      ...ServiceRequestStatuses.map((status) => ({
                        value: status,
                        label: status,
                      })),
                    ]}
                    placeholder='Select Status'
                    values={{ status }}
                  />
                </div>
              )}
              // renderExport={({ exportOpen, setExportOpen }) => (
              //   <ExportDataContainer
              //     open={exportOpen}
              //     setOpen={setExportOpen}
              //     entityType="Regularization"
              //     filter={filter}
              //   />
              // )}

              renderColumns={() => (
                <tr>
                  <th
                    scope='col'
                    className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                  >
                    Code | Date
                  </th>
                  {!searchParams?.region && (
                    <th
                      scope='col'
                      className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                    >
                      Region
                    </th>
                  )}
                  {!searchParams?.district && (
                    <th
                      scope='col'
                      className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                    >
                      District
                    </th>
                  )}
                  <th
                    scope='col'
                    className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                  >
                    Customer Type
                  </th>
                  <th
                    scope='col'
                    className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                  >
                    Customer
                  </th>
                  <th
                    scope='col'
                    className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                  >
                    Category
                  </th>
                  <th
                    scope='col'
                    className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                  >
                    GhanaPost | Community
                  </th>
                  <th
                    scope='col'
                    className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                  >
                    Service Type
                  </th>
                  <th
                    scope='col'
                    className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                  >
                    Status
                  </th>
                  <th
                    scope='col'
                    className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                  >
                    Created By
                  </th>
                  <th
                    scope='col'
                    className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                  ></th>
                </tr>
              )}
              renderLoader={() => (
                <tr>
                  <td className='px-6 py-4 border-b border-gray-200'>
                    <Shimmers.DoubleShimmer />
                  </td>
                  {!searchParams?.region && (
                    <td className='px-6 py-4 border-b border-gray-200'>
                      <Shimmers.DoubleShimmer />
                    </td>
                  )}
                  {!searchParams?.district && (
                    <td className='px-6 py-4 border-b border-gray-200'>
                      <Shimmers.DoubleShimmer />
                    </td>
                  )}
                  <td className='px-6 py-4 border-b border-gray-200'>
                    <Shimmers.SingleShimmer />
                  </td>
                  <td className='px-6 py-4 border-b border-gray-200'>
                    <Shimmers.DoubleShimmer />
                  </td>
                  <td className='px-6 py-4 border-b border-gray-200'>
                    <Shimmers.SingleShimmer />
                  </td>
                  <td className='px-6 py-4 border-b border-gray-200'>
                    <Shimmers.DoubleShimmer />
                  </td>
                  <td className='px-6 py-4 border-b border-gray-200'>
                    <Shimmers.DoubleShimmer />
                  </td>
                  <td className='px-6 py-4 border-b border-gray-200'>
                    <Shimmers.SingleShimmer />
                  </td>
                  <td className='px-6 py-4 border-b border-gray-200'>
                    <Shimmers.ActionsShimmer actionsCount={2} />
                  </td>
                </tr>
              )}
              renderItem={(item) => (
                <tr
                  key={item._id}
                  className='hover:bg-gray-50 dark:hover:bg-gray-800 cursor-pointer'
                  onClick={wrapClick(dispatchAction(item._id, "view"))}
                >
                  <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 '>
                    <div className='font-medium text-gray-900 dark:text-gray-100'>
                      {item?.code || "N/A"}
                    </div>
                    <div className='text-gray-500 dark:text-gray-400'>
                      {moment(item?.createdAt).format(dateFormat)}
                    </div>
                  </td>
                  {!searchParams?.region && (
                    <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 '>
                      <div className='font-medium text-gray-900 dark:text-gray-100'>
                        {item?.region?.name || "N/A"}
                      </div>
                      <div className='text-gray-500 dark:text-gray-400'>
                        {item?.region?.code || "N/A"}
                      </div>
                    </td>
                  )}
                  {!searchParams?.district && (
                    <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 '>
                      <div className='font-medium text-gray-900 dark:text-gray-100'>
                        {item?.district?.name || "N/A"}
                      </div>
                      <div className='text-gray-500 dark:text-gray-400'>
                        {item?.district?.code || "N/A"}
                      </div>
                    </td>
                  )}
                  <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200'>
                    <span
                      className={classNames(
                        "bg-gray-100 text-gray-800",
                        item?.customerData?.customerType === "Individual"
                          ? `bg-green-100 text-green-800 dark:bg-green-600 dark:text-gray-50`
                          : "",
                        item?.customerData?.customerType === "Organization"
                          ? `bg-green-100 text-green-800 dark:bg-green-600 dark:text-gray-50`
                          : "",
                        !item?.customerData?.customerType
                          ? "bg-gray-200 text-gray-800 dark:bg-gray-600 dark:text-gray-100"
                          : "",
                        "inline-flex rounded-full  px-2 py-1 text-xs items-center space-x-1"
                      )}
                    >
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        viewBox='0 0 10 10'
                        fill='currentColor'
                        className='w-1.5 h-1.5'
                      >
                        <circle
                          fillRule='evenodd'
                          cx='5'
                          cy='5'
                          r='5'
                          clipRule='evenodd'
                        />
                      </svg>
                      <span>
                        {item?.customerData?.customerType || "Unknown"}
                      </span>
                    </span>
                  </td>
                  <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200'>
                    {item?.customerData?.customerType === "Individual" ? (
                      <>
                        <div className='text-gray-900 dark:text-gray-100'>
                          {item?.customerData?.representative?.fullName ||
                            "N/A"}
                        </div>
                        <div className='text-gray-500 dark:text-gray-400'>
                          {item?.customerData?.representative?.phoneNumber ||
                            "N/A"}
                        </div>
                      </>
                    ) : (
                      <>
                        <div className='text-gray-900 dark:text-gray-100'>
                          {item?.customerData?.organization?.name || "N/A"}
                        </div>
                        <div className='text-gray-500 dark:text-gray-400'>
                          {item?.customerData?.representative?.phoneNumber ||
                            "N/A"}
                        </div>
                      </>
                    )}
                  </td>
                  <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200'>
                    <span
                      className={classNames(
                        "bg-gray-100 text-gray-800",
                        item?.billing?.neighbourhoodClass === "Urban"
                          ? `bg-primary-100 text-primary-800`
                          : "",
                        item?.billing?.neighbourhoodClass === "PeriUrban"
                          ? `bg-cyan-100 text-cyan-800`
                          : "",
                        item?.billing?.neighbourhoodClass === "Rural"
                          ? `bg-purple-100 text-purple-800`
                          : "",
                        "inline-flex rounded-full  px-2 py-1 text-xs items-center space-x-1"
                      )}
                    >
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        viewBox='0 0 10 10'
                        fill='currentColor'
                        className='w-1.5 h-1.5'
                      >
                        <circle
                          fillRule='evenodd'
                          cx='5'
                          cy='5'
                          r='5'
                          clipRule='evenodd'
                        />
                      </svg>
                      <span>
                        {item?.billing?.neighbourhoodClass || "Unknown"}
                      </span>
                    </span>
                  </td>
                  <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200'>
                    <div className='text-gray-900 dark:text-gray-100'>
                      {item?.property?.ghanaPostAddress || "N/A"}
                    </div>
                    <div className='text-gray-500 dark:text-gray-400'>
                      {item?.property?.community || "N/A"}
                    </div>
                  </td>
                  <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200'>
                    <span
                      className={classNames(
                        "bg-gray-100 text-gray-800",
                        item?.service?.serviceClass === "Residential"
                          ? `bg-primary-100 text-primary-800`
                          : "",
                        item?.service?.serviceClass === "NonResidential"
                          ? `bg-cyan-100 text-cyan-800`
                          : "",
                        item?.service?.serviceClass === "Industrial"
                          ? `bg-purple-100 text-purple-800`
                          : "",
                        "inline-flex rounded-full  px-2 py-1 text-xs items-center space-x-1"
                      )}
                    >
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        viewBox='0 0 10 10'
                        fill='currentColor'
                        className='w-1.5 h-1.5'
                      >
                        <circle
                          fillRule='evenodd'
                          cx='5'
                          cy='5'
                          r='5'
                          clipRule='evenodd'
                        />
                      </svg>
                      <span>
                        {_.startCase(item?.service?.serviceClass) || "Unknown"}
                      </span>
                    </span>
                  </td>
                  <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200'>
                    <span
                      className={classNames(
                        "bg-gray-100 text-gray-800",
                        item?.status === "SuperAdmin"
                          ? `bg-gray-100 text-[#F87A17] dark:bg-[#F87A17] dark:text-gray-50`
                          : "",
                        item?.status === "Admin"
                          ? `bg-gray-100 text-[#657383] dark:bg-[#657383] dark:text-gray-50`
                          : "",
                        item?.status === "Supervisor"
                          ? `bg-gray-100 text-[#FF4040] dark:bg-[#FF4040] dark:text-gray-50`
                          : "",
                        item?.status === "Reader"
                          ? `bg-gray-100 text-[#9ACD32] dark:bg-[#9ACD32] dark:text-gray-50`
                          : "",
                        item?.status === "Queued"
                          ? `bg-gray-100 text-[#D4A017] dark:bg-[#D4A017] dark:text-gray-50`
                          : "",
                        item?.status === "Success"
                          ? `bg-green-100 text-green-800 dark:bg-green-600 dark:text-gray-50`
                          : "",
                        item?.status === "Reversed"
                          ? `bg-green-100 text-green-800 dark:bg-green-600 dark:text-gray-50`
                          : "",
                        !item?.status
                          ? "bg-gray-200 text-gray-800 dark:bg-gray-600 dark:text-gray-100"
                          : "",
                        "inline-flex rounded-full  px-2 py-1 text-xs items-center space-x-1"
                      )}
                    >
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        viewBox='0 0 10 10'
                        fill='currentColor'
                        className='w-1.5 h-1.5'
                      >
                        <circle
                          fillRule='evenodd'
                          cx='5'
                          cy='5'
                          r='5'
                          clipRule='evenodd'
                        />
                      </svg>
                      <span>{_.startCase(item?.status || "Unknown")}</span>
                    </span>
                  </td>
                  <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 ">
                    <div className="flex items-center">
                      <div className="h-10 w-10 flex-shrink-0">
                        <Avatar
                          disabled={true}
                          alt={
                            [
                              (item?.createdBy?.lastName || "")?.trim(),
                              (item?.createdBy?.firstName || "")?.trim(),
                            ]
                              .join(" ")
                              .trim() || "N A"
                          }
                          src={item?.createdBy?.profileImageUrl || ""}
                        />
                      </div>
                      <div className="ml-4">
                        <div className="text-gray-900 dark:text-gray-100">
                          {[
                            (item?.createdBy?.lastName || "")?.trim(),
                            (item?.createdBy?.firstName || "")?.trim(),
                          ]
                            .join(" ")
                            .trim() || "N A"}
                        </div>
                        <div className="text-gray-500 dark:text-gray-400">
                          {item?.createdBy?.phoneNumber || "N/A"}
                        </div>
                      </div>
                    </div>
                  </td>
                  <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 text-right'>
                    <div className='space-x-1'>
                      <ActionButton
                        action='view'
                        onClick={dispatchAction(item?._id, "view")}
                      />
                      {/* <ActionButton
                      action='expand'
                      disabled
                      onClick={navigateItem(item?._id)}
                    /> */}
                    </div>
                  </td>
                </tr>
              )}
            />
          )}
        </div>
      </div>

      <BulkCreateRegularizationsContainer
        open={modal === "create-bulk"}
        setOpen={(val: boolean) => setModal(val ? "create-bulk" : undefined)}
        refetch={refetch}
      />
      {!!searchParams.id?.length && (
        <>
          <ViewBlockContainer
            open={modal === "view"}
            setOpen={(val: boolean) => setModal(val ? "view" : undefined)}
          />
          <ViewRegularizationInitiationContainer
            open={modal === "viewBulk"}
            setOpen={(val: boolean) => setModal(val ? "viewBulk" : undefined)}
          />
        </>
      )}
    </main>
  );
};

export default ServiceRegularizationsPage;
