import { FC, useRef } from "react";
import { classNames, wrapClick } from "utils";
import { useReactToPrint } from "react-to-print";
import {
  EnvelopeIcon,
  GlobeAltIcon,
  PhoneIcon,
} from "@heroicons/react/20/solid";
import { gql, useMutation, useQuery } from "@apollo/client";
import { TextInput } from "components/core";
import { useFormik } from "formik";
import toast from "react-hot-toast";

interface ContractSigningDetailsContainerProps {
  values: any;
  id: string;
  code: string;
  status: string;
  createdAt: string;
  updatedAt: string;
  refetch?: () => void;
}

const GET_CONTRACT = gql`
  query GetContractDetails($id: ID!) {
    regularization: getRegularization(id: $id) {
      _id
      code
      region {
        _id
        code
        name
      }
      district {
        _id
        code
        name
      }
      customerData {
        customerType

        organization {
          name
          taxIdentificationNumber
          organizationRegistrationNumber
          organizationRegistrationDate
          organizationRegistrationDocumentUrl
          certificateOfIncorporationDocumentUrl
        }
        representative {
          title
          fullName
          nationality
          phoneNumber
          emailAddress
          profileImageUrl
          hasGhanaCard
          ghanaCardNumber
          ghanaCardIssueDate
          ghanaCardExpiryDate
          ghanaCardFrontImageUrl
          ghanaCardBackImageUrl
          identityCardType
          identityCardNumber
          identityCardIssueDate
          identityCardExpiryDate
          identityCardFrontImageUrl
          identityCardBackImageUrl
        }
      }
      property {
        region {
          _id
          code
          name
        }
        district {
          _id
          code
          name
        }
        ghanaPostAddress
        community
        streetName
        houseNumber
        landmark
        activity {
          _id
          code
          name
        }
      }
      service {
        serviceType
        serviceClass
        energyCertificateNumber
        energyCertificateDocumentUrl
      }
      status
      createdAt
      updatedAt
      accountCode
    }
  }
`;

const SIGN_CONTRACT = gql`
  mutation ($id: ID!, $passcode: String!) {
    signRegularizationContract(id: $id, passcode: $passcode) {
      _id
    }
  }
`;

const ContractSigningDetailsContainer: FC<
  ContractSigningDetailsContainerProps
> = ({ values, code, id, refetch }) => {
  const printRef = useRef<any>();
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    documentTitle: code,
    bodyClass: "w-[1000px]",
  });
  const { data, refetch: refetchContract } = useQuery(GET_CONTRACT, {
    variables: {
      id,
    },
    fetchPolicy: "network-only",
  });

  const [signContract, { loading }] = useMutation(SIGN_CONTRACT);

  const form = useFormik({
    initialValues: {
      passcode: "",
    },
    onSubmit: async (values) => {
      await signContract({
        variables: {
          id: id,
          passcode: values.passcode,
        },
      }).then(({ data }) => {
        if (data?.signRegularizationContract?._id) {
          toast(
            JSON.stringify({
              type: "success",
              title: "Contract signed Successfully",
            })
          );
          refetch?.();
          refetchContract?.();
          form.resetForm();
        } else {
          toast(
            JSON.stringify({ type: "error", title: "Contract signing failed" })
          );
        }
      });
    },
  });

  return (
    <div className="flex-1 flex flex-col overflow-hidden">
      <div ref={printRef} className="flex-1 flex flex-col overflow-hidden">
        <div className="hidden print:flex flex-row items-center space-x-4 justify-center py-6 border-b border-gray-300">
          <div>
            <img
              alt="ECG Logo"
              src={require("assets/logo-new.png")}
              className="h-32 w-32"
            />
          </div>
          <div className="space-y-1 text-gray-900">
            <h1 className="text-xl text-blue-800 font-bold">
              ELECTRICITY COMPANY OF GHANA LIMITED
            </h1>
            <div className="flex items-center space-x-6">
              <div className="flex items-center space-x-2">
                <EnvelopeIcon className="h-4 w-4" />
                <span>P.O BOX GP 521, ACCRA</span>
              </div>
              <div className="flex items-center space-x-2">
                <PhoneIcon className="h-4 w-4" />
                <span>0302-611-611</span>
              </div>
              <div className="flex items-center space-x-2">
                <GlobeAltIcon className="h-4 w-4" />
                <span>ecg@ecggh.com</span>
              </div>
            </div>
            <div className="flex items-center space-x-6">
              <div className="flex items-center space-x-2">
                <span className="font-semibold">VAT REG:</span>
                <span>714V000395</span>
              </div>
              <div className="flex items-center space-x-2">
                <span className="font-semibold">Website:</span>
                <span>https://www.ecggh.com/</span>
              </div>
            </div>
          </div>
        </div>
        <div className="space-y-6 divide-y divide-gray-200 p-6 overflow-y-auto flex-1">
          <div className="p-4">
            <div className="flex justify-between items-center ">
              <div>
                <h3 className="text-md leading-6 font-semibold text-gray-900 dark:text-gray-100">
                  Contract For Supply Of Electricity
                </h3>
              </div>
              <div>
                <h3 className="text-md leading-6 font-semibold text-gray-900 dark:text-gray-100">
                  Contract No.:{" "}
                  <span className="underline tracking-wider">
                    {data?.regularization?.contract?.contractNumber || "N/A"}
                  </span>
                </h3>
              </div>
            </div>
            <div className="mt-6">
              <div>
                <h3 className="font-semibold text-sm text-blue-800 uppercase">
                  Customer Information
                </h3>
              </div>
              <div className="mt-2 grid grid-cols-5 border border-gray-300 divide-x divide-gray-300">
                <div className="col-span-2 grid grid-cols-2 gap-6 p-4">
                  <div className="col-span-2">
                    <span className="block text-sm">Name</span>
                    <div className="mt-1 block w-full text-sm font-light text-gray-700">
                      {data?.regularization?.customerData?.representative
                        ?.fullName ||
                        data?.regularization?.customerData?.representative
                          ?.fullName ||
                        "N/A"}
                    </div>
                  </div>
                  {data?.regularization?.customerData?.representative
                    ?.hasGhanaCard ||
                  data?.regularization?.customerData?.representative
                    ?.hasGhanaCard ? (
                    <>
                      <div>
                        <span className="block text-sm">ID Type</span>
                        <div className="mt-1 block w-full text-sm font-light text-gray-700">
                          NIA
                        </div>
                      </div>
                      <div>
                        <span className="block text-sm">ID Number</span>
                        <div className="mt-1 block w-full text-sm font-light text-gray-700">
                          {data?.regularization?.customerData?.representative
                            ?.ghanaCardNumber ||
                            data?.regularization?.customerData?.representative
                              ?.ghanaCardNumber ||
                            "N/A"}
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div>
                        <span className="block text-sm">ID Type</span>
                        <div className="mt-1 block w-full text-sm font-light text-gray-700">
                          {data?.regularization?.customerData?.representative
                            ?.identityCardType ||
                            data?.regularization?.customerData?.representative
                              ?.identityCardType ||
                            "N/A"}
                        </div>
                      </div>
                      <div>
                        <span className="block text-sm">ID Number</span>
                        <div className="mt-1 block w-full text-sm font-light text-gray-700">
                          {data?.regularization?.customerData?.representative
                            ?.identityCardNumber ||
                            data?.regularization?.customerData?.representative
                              ?.identityCardNumber ||
                            "N/A"}
                        </div>
                      </div>
                    </>
                  )}
                  <div>
                    <span className="block text-sm">Account No.</span>
                    <div className="mt-1 block w-full text-sm font-light text-gray-700">
                      {data?.regularization?.contract?.accountNumber || "N/A"}
                    </div>
                  </div>
                  <div>
                    <span className="block text-sm">Payer Name</span>
                    <div className="mt-1 block w-full text-sm font-light text-gray-700">
                      {data?.regularization?.customerData?.representative
                        ?.fullName ||
                        data?.regularization?.customerData?.representative
                          ?.fullName ||
                        "N/A"}
                    </div>
                  </div>
                </div>
                <div className="col-span-3 grid grid-cols-2 gap-6 p-4">
                  <div className="col-span-2">
                    <span className="block text-sm">Address</span>
                    <div className="mt-1 block w-full text-sm font-light text-gray-700">
                      {data?.regularization?.property?.ghanaPostAddress ||
                        "N/A"}
                    </div>
                  </div>
                  <div>
                    <span className="block text-sm">Email</span>
                    <div className="mt-1 block w-full text-sm font-light text-gray-700">
                      {data?.regularization?.customerData?.representative
                        ?.emailAddress ||
                        data?.regularization?.customerData?.representative
                          ?.emailAddress ||
                        "N/A"}
                    </div>
                  </div>
                  <div>
                    <span className="block text-sm">Mobile No.</span>
                    <div className="mt-1 block w-full text-sm font-light text-gray-700">
                      {data?.regularization?.customerData?.representative
                        ?.phoneNumber ||
                        data?.regularization?.customerData?.representative
                          ?.phoneNumber ||
                        "N/A"}
                    </div>
                  </div>
                  <div>
                    <span className="block text-sm">Telephone</span>
                    <div className="mt-1 block w-full text-sm font-light text-gray-700">
                      {data?.regularization?.customerData?.representative
                        ?.phoneNumber ||
                        data?.regularization?.customerData?.representative
                          ?.phoneNumber ||
                        "N/A"}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-6">
              <div>
                <h3 className="font-semibold text-sm text-blue-800 uppercase">
                  Contract Information
                </h3>
              </div>
              <div className="mt-2 grid grid-cols-5 border border-gray-300 divide-x divide-gray-300">
                <div className="col-span-2 grid grid-cols-2 gap-6 p-4">
                  <div className="">
                    <span className="block text-sm">Demand</span>
                    <div className="mt-1 block w-full text-sm font-light text-gray-700">
                      {data?.regularization?.service?.contractedDemand || "N/A"}{" "}
                      KVh
                    </div>
                  </div>
                  <div>
                    <span className="block text-sm">Deposit</span>
                    <div className="mt-1 block w-full text-sm font-light text-gray-700">
                      {data?.meterNumber || "N/A"}
                    </div>
                  </div>
                  <div>
                    <span className="block text-sm">Supply Purpose</span>
                    <div className="mt-1 block w-full text-sm font-light text-gray-700">
                      {data?.regularization?.property?.activity?.name || "N/A"}
                    </div>
                  </div>
                  <div>
                    <span className="block text-sm">Tariff</span>
                    <div className="mt-1 block w-full text-sm font-light text-gray-700">
                      {data?.regularization?.service?.serviceClass || "N/A"}
                    </div>
                  </div>
                  <div>
                    <span className="block text-sm">Termination Date</span>
                    <div className="mt-1 block w-full text-sm font-light text-gray-700">
                      {"N/A"}
                    </div>
                  </div>
                  <div>
                    <span className="block text-sm">Exemptions</span>
                    <div className="mt-1 block w-full text-sm font-light text-gray-700">
                      {"N/A"}
                    </div>
                  </div>
                </div>
                <div className="col-span-3 grid grid-cols-2 gap-6 p-4">
                  <div className="col-span-2">
                    <span className="block text-sm">Supply Address</span>
                    <div className="mt-1 block w-full text-sm font-light text-gray-700">
                    {[data?.regularization?.property?.houseNumber, data?.regularization?.property?.streetName, data?.regularization?.property?.community].join(" ").trim() || "N/A"}
                    </div>
                  </div>
                  <div>
                    <span className="block text-sm">Landmark</span>
                    <div className="mt-1 block w-full text-sm font-light text-gray-700">
                      {data?.regularization?.property?.landmark || "N/A"}
                    </div>
                  </div>
                  <div>
                    <span className="block text-sm">Area</span>
                    <div className="mt-1 block w-full text-sm font-light text-gray-700">
                      {data?.regularization?.property?.community || "N/A"}
                    </div>
                  </div>
                  <div>
                    <span className="block text-sm">District</span>
                    <div className="mt-1 block w-full text-sm font-light text-gray-700">
                      {data?.regularization?.property?.district?.name || "N/A"}
                    </div>
                  </div>
                  <div>
                    <span className="block text-sm">Region</span>
                    <div className="mt-1 block w-full text-sm font-light text-gray-700">
                      {data?.regularization?.property?.region?.name || "N/A"}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-6">
              <div className="space-y-6 text-sm text-justify leading-loose">
                <p className="">
                  I hereby state that all the information provided by me on this
                  application form is true and accurate. I declare also that the
                  electrical installation in the premises intended to be
                  connected has been undertaken, inspected and tested by a
                  competent and licensed Electrical Contractor to comply with
                  the relevant regulations.
                </p>
                <p className="">
                  I further firmly state that I will promptly make available to
                  ECG the Electrical Contractor´s certification that the
                  installation complies with the relevant regulations upon
                  request at any time.
                </p>
                <p className="">
                  Finally, I undertake to indemnify the Electricity Company of
                  Ghana Limited. from any liability arising from any damage or
                  injury caused by connecting electricity to the electrical
                  installation except where it has been shown that the injury or
                  damage was due to the negligence of the staff or agent of the
                  Electricity Company of Ghana Limited, who connected the
                  electricity to the electrical Installation.
                </p>
              </div>
            </div>
            <div className="mt-20">
              <div className="grid grid-cols-3 gap-x-24 gap-y-12">
                <div className="border-t border-gray-600 pt-4">
                  Customer Signature
                </div>
                <div className="border-t border-gray-600 pt-4">
                  District Manager's Name
                </div>
                <div className="border-t border-gray-600 pt-4">
                  Witness' Name
                </div>
                <div />
                <div className="border-t border-gray-600 pt-4">
                  District Manager's Signature
                </div>
                <div className="border-t border-gray-600 pt-4">
                  Witness' Signature
                </div>
              </div>
            </div>
            <div className="hidden print:block mt-20 text-xs">
              See conditions of supply at the back
            </div>
          </div>
        </div>
      </div>

      <div className="bg-gray-50 dark:bg-gray-800 px-4 py-3 sm:py-4 sm:px-6 sm:flex sm:flex-row-reverse border-t border-gray-200">
        <button
          type="button"
          onClick={wrapClick(handlePrint)}
          className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
        >
          Print
        </button>
        {data?.regularization?.contract?.status === "Pending" && (
          <form onSubmit={form.handleSubmit} className="flex flex-row">
            <TextInput
              id="passcode"
              labelHidden={true}
              placeholder="Authorization Code"
              maxLength={6}
              minLength={6}
              {...form}
            />
            <button
              type="submit"
              disabled={loading}
              className={classNames(
                loading ? "cursor-not-allowed" : "cursor-pointer",
                "mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 dark:border-gray-600 shadow-sm px-4 py-2 bg-white dark:bg-gray-900 text-base font-medium text-gray-700 dark:text-gray-200 hover:bg-gray-50 hover:dark:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
              )}
            >
              {loading ? "Signing Contract..." : "Sign Contract"}
            </button>
          </form>
        )}
      </div>
    </div>
  );
};

export default ContractSigningDetailsContainer;
