import { useState } from "react";
import { classNames, useDownloadFile, wrapClick } from "utils";
import { gql, useLazyQuery, useQuery } from "@apollo/client";
import {
  Modal,
  RegularizationInitiationsView,
  RegularizationInitiationView,
} from "components";
import { useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import lodash from "lodash";
import toast from "react-hot-toast";

export const GET_SERVICE_ORDER = gql`
  query GetRegularizationInitiation($id: ID!) {
    regularization: getRegularizationInitiation(id: $id) {
      _id
      code
      description
      region {
        _id
        code
        name
      }
      district {
        _id
        code
        name
      }
      status
      initiations {
        _id
        status
        statusReason
        ghanaPostAddress
        phoneNumber
        meterPhase
        customerType
        organizationName
        taxIdentificationNumber
        organizationRegistrationNumber
        organizationRegistrationDate
        organizationRegistrationDocumentUrl
        certificateOfIncorporationDocumentUrl
        title
        representativeName
        nationality
        dateOfBirth
        gender
        emailAddress
        profileImageUrl
        hasGhanaCard
        ghanaCardNumber
        ghanaCardIssueDate
        ghanaCardExpiryDate
        ghanaCardFrontImageUrl
        ghanaCardBackImageUrl
        identityCardType
        identityCardNumber
        identityCardIssueDate
        identityCardExpiryDate
        identityCardFrontImageUrl
        identityCardBackImageUrl
        propertyOwnerName
        propertyOwnerPhoneNumber
        community
        streetName
        houseNumber
        structureNumber
        landmark
        premiseTypeCode
        premiseCategoryCode
        activityCode
        subActivityCode
        longitude
        latitude
        sitePlanDocumentUrl
        serviceType
        serviceClass
        energyCertificateIssuerId
        energyCertificateIssuerName
        energyCertificateIssuerPhone
        energyCertificateNumber
        energyCertificateDocumentUrl
        neighbourhoodClass
        numberOfRooms
        numberOfMonths
        meterNumber
        meterImageUrl
      }
      meta {
        totalInitiations
        totalFailedInitiations
        totalSuccessInitiations
      }
      createdBy {
        _id
        code
        lastName
        firstName
        emailAddress
        phoneNumber
      }
      createdAt
      updatedAt
    }
  }
`;

export const GET_SERVICE_ORDER_EXPORT_URL = gql`
  query Query($id: ID!) {
    getRegularizationInitiationExportUrl(id: $id)
  }
`;

const orderTabs = [
  {
    name: "Work Order Details",
    href: "WorkOrderDetails",
  },
  {
    name: "Successful Initiations",
    href: "SuccessfulInitiations",
  },
  {
    name: "Failed Initiations",
    href: "FailedInitiations",
  },
];

export default function ViewRegularizationInitiationContainer({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
}) {
  const searchParams = useSearch<LocationGenerics>();
  const [orderTab, setOrderTab] = useState("WorkOrderDetails");
  const { data, loading } = useQuery(GET_SERVICE_ORDER, {
    variables: {
      id: searchParams.id,
    },
    notifyOnNetworkStatusChange: false,
    fetchPolicy: "network-only",
  });
  const [createDataExport, { loading: gettingUrl }] = useLazyQuery(
    GET_SERVICE_ORDER_EXPORT_URL,
    {
      fetchPolicy: "no-cache",
    }
  );
  const __setOrderTab = (tab: string) => () => {
    setOrderTab(tab);
  };

  const { downloadAction, downloadLoading } = useDownloadFile({
    onError: () => {
      toast(
        JSON.stringify({
          type: "error",
          title: "An error occurred downloading file",
        })
      );
    },
    getFileName: () => new Date().toISOString() + "_FailedInitiations.xlsx",
  });

  const handleDownloadItem = () => {
    createDataExport({
      variables: {
        id: data?.regularization?._id,
      },
      fetchPolicy: "no-cache",
    }).then(({ data }) => {
      if (data.getRegularizationInitiationExportUrl) {
        downloadAction(data.getRegularizationInitiationExportUrl);
      } else {
        toast(
          JSON.stringify({ type: "error", title: "Could not export file" })
        );
      }
    });
  };

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      loading={loading}
      hidePadding={true}
      title='Regularization Initiations Information'
      size='6xl'
      description='Details of regularization upload are shown below'
      renderActions={() => (
        <>
          {orderTab === "FailedInitiations" && (
            <button
              type='button'
              onClick={wrapClick(handleDownloadItem)}
              disabled={gettingUrl || downloadLoading}
              className='w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm'
            >
              Download Failed Initiations
            </button>
          )}
        </>
      )}
    >
      {data?.regularization?._id && (
        <div className='flex-1 flex flex-col overflow-hidden'>
          <div className='block'>
            <div className='border-b border-gray-200 bg-white px-6'>
              <nav className='-mb-px flex space-x-8' aria-label='Tabs'>
                {orderTabs.map((_orderTab) => (
                  <button
                    key={_orderTab.name}
                    onClick={wrapClick(__setOrderTab(_orderTab.href))}
                    className={classNames(
                      orderTab === _orderTab.href
                        ? "border-primary-500 text-primary-600"
                        : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200",
                      "whitespace-nowrap disabled:cursor-not-allowed flex py-4 px-1 border-b-2 font-medium text-sm"
                    )}
                    aria-current={
                      orderTab === _orderTab.href ? "page" : undefined
                    }
                  >
                    {_orderTab.name}
                  </button>
                ))}
              </nav>
            </div>
          </div>
          <div className='flex flex-col w-full h-[75vh] overflow-y-auto'>
            {orderTab === "WorkOrderDetails" && (
              <RegularizationInitiationView
                regularization={data?.regularization}
              />
            )}
            {orderTab === "SuccessfulInitiations" && (
              <RegularizationInitiationsView
                initiations={lodash.filter(data?.regularization?.initiations, [
                  "status",
                  "Success",
                ])}
                type='Successful'
              />
            )}
            {orderTab === "FailedInitiations" && (
              <RegularizationInitiationsView
                initiations={lodash.filter(data?.regularization?.initiations, [
                  "status",
                  "Failed",
                ])}
                type='Failed'
              />
            )}
          </div>
        </div>
      )}
    </Modal>
  );
}
