import { ReactElement } from "react";
import { ReactLocation, MakeGenerics } from "react-location";
import { parseSearch, stringifySearch } from "react-location-jsurl";

export type LocationGenerics = MakeGenerics<{
  Search: {
    redirect?: string;
    fromDate?: string;
    toDate?: string;
    page?: number;
    pageSize?: number;
    search?: string;
    sort?: string;
    status: string;
    modal?:
    | "create"
    | "update"
    | "view"
    | "configure"
    | "calculate"
    | "assign"
    | "schedule"
    | "send"
    | "resolve"
    | "approve"
    | "reject"
    | "terminate"
    | "reassign"
    | "export"
    | "import"
    | "suspend"
    | "restore"
    | "ticket"
    | "investigate"
    | "updateIdentity"
    | "viewComplaint"
  | "viewBulk"
    | "updateLocation"
    | "updateTariff";
    id?: string;
    region?: string;
    district?: string;
    block?: string;
    round?: string;
    plot?: string;
    itinerary?: string;
    property?: string;
    role?: "SuperAdmin" | "Admin" | "Supervisor" | "Reader";
    month?: number;
    year?: number;
    tariffClass?: string;
    customerType?: string;
    serviceType?: string;
    serviceClass?: string;
    view?: "day" | "week" | "month" | "quarter" | "year" | "custom";
    orderStatus?: string;
    orderType?: string;
    regularizationsKind?: string;
    orderInitiationStatus?: string;
    initiationStatus?: string;
    token?: string;
    brand?: string;
    model?: string;
    location?: string;
  };
  Params: {
    region?: string;
    district?: string;
    block?: string;
    round?: string;
    plot?: string;
    itinerary?: string;
    tariffClass?: string;
    readingSet?: string;
    serviceRequest?: string;
    serviceRegularization?: string;
    servicePoint?: string;
    complaint?: string;
  };
  RouteMeta: {
    layout?: "App" | "Auth";
    breadcrumb: (params: LocationGenerics["Params"]) => ReactElement;
    section?: "General" | "Structure" | "Configuration" | "Users & Profiles";
  };
}>;

const location = new ReactLocation({
  parseSearch,
  stringifySearch,
});

export default location;
