import { useReactiveVar } from "@apollo/client";
import { currentConfigVar } from "apollo/cache/config";
import { ActionButton } from "components/buttons";
import { SearchSelectInput, Avatar } from "components/core";
import { TableComponent, Shimmers } from "components/tables";
import moment from "moment";
import numeral from "numeral";
import { FC } from "react";
import { useUrlState, wrapClick, classNames } from "utils";

interface RegularizationInitiationsTableProps {
  isRefetching: boolean;
  loading: boolean;
  refetch: () => void;
  data: any;
  onSearch: (search: string, searchField: string) => void;
  dispatchAction: (id: string, action: any) => () => void;
}

const RegularizationInitiationsTable: FC<
  RegularizationInitiationsTableProps
> = ({ isRefetching, loading, refetch, data, dispatchAction, onSearch }) => {
  const { dateFormat } = useReactiveVar(currentConfigVar);
  const [initiationStatus, setInitiationStatus] =
    useUrlState("initiationStatus");

  return (
    <TableComponent
      title={"Regularization Initiations"}
      refetch={refetch}
      isRefetching={isRefetching}
      loading={loading}
      data={data}
      hasSearch={true}
      onSearchSubmit={onSearch}
      // searchOptions={[
      //   {
      //     label: "Code",
      //     value: "code",
      //   },
    
      // ]}
      renderHeaderItems={() => (
        <div className='mt-4 sm:mt-0.5 sm:ml-16 sm:flex space-x-2'>
          <SearchSelectInput
            id='initiationStatus'
            labelHidden={true}
            options={[
              { label: { title: "Pending" }, value: "Pending" },
              { label: { title: "In Progress" }, value: "InProgress" },
              { label: { title: "Completed" }, value: "Completed" },
            ]}
            label={""}
            placeholder='Select  Upload Status'
            setFieldValue={(_: any, value: string) => {
              !value
                ? setInitiationStatus(undefined)
                : setInitiationStatus(value);
            }}
            values={{ initiationStatus: initiationStatus }}
            setFieldTouched={undefined}
            setFieldError={undefined}
          />
        </div>
      )}
      renderColumns={() => (
        <tr>
          <th
            scope='col'
            className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
          >
            Code
          </th>
          <th
            scope='col'
            className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
          >
            Date
          </th>
          <th
            scope='col'
            className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
          >
            District
          </th>
          <th
            scope='col'
            className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-right text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
          >
            Success Initiations
          </th>
          <th
            scope='col'
            className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-right text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
          >
            Failed Initiations
          </th>
          <th
            scope='col'
            className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-right text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
          >
            Total Initiations
          </th>
          <th
            scope='col'
            className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
          >
            Status
          </th>
          <th
            scope='col'
            className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
          >
            Created By
          </th>
          <th
            scope='col'
            className='sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
          ></th>
        </tr>
      )}
      renderLoader={() => (
        <tr>
          <td className='px-6 py-4 border-b border-gray-200'>
            <Shimmers.SingleShimmer />
          </td>
          <td className='px-6 py-4 border-b border-gray-200'>
            <Shimmers.SingleShimmer />
          </td>
          <td className='px-6 py-4 border-b border-gray-200'>
            <Shimmers.SingleShimmer />
          </td>
          <td className='px-6 py-4 border-b border-gray-200'>
            <Shimmers.SingleShimmer />
          </td>
          <td className='px-6 py-4 border-b border-gray-200'>
            <Shimmers.SingleShimmer />
          </td>
          <td className='px-6 py-4 border-b border-gray-200'>
            <Shimmers.SingleShimmer />
          </td>
          <td className='px-6 py-4 border-b border-gray-200'>
            <Shimmers.SingleShimmer />
          </td>
          <td className='px-6 py-4 border-b border-gray-200'>
            <Shimmers.DoubleShimmer />
          </td>
          <td className='px-6 py-4 border-b border-gray-200'>
            <Shimmers.ActionsShimmer actionsCount={4} />
          </td>
        </tr>
      )}
      renderItem={(item) => (
        <tr
          key={item._id}
          className='hover:bg-gray-50 dark:hover:bg-gray-800 cursor-pointer'
          onClick={wrapClick(dispatchAction(item._id, "viewBulk"))}
        >
          <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 '>
            <div className='font-medium text-gray-900 dark:text-gray-100'>
              {item?.code || "N/A"}
            </div>
          </td>
          <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200'>
            {moment(item?.createdAt).format(dateFormat)}
          </td>
          <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 '>
            {item?.district?.name || "N/A"} ({item?.district?.code || "N/A"})
          </td>
          <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 text-right border-b border-gray-200'>
            {numeral(item?.meta?.totalSuccessInitiations).format("0,0") ??
              "N/A"}
          </td>
          <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 text-right  border-b border-gray-200'>
            {numeral(item?.meta?.totalFailedInitiations).format("0,0") ?? "N/A"}
          </td>
          <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 text-right  border-b border-gray-200'>
            {numeral(item?.meta?.totalInitiations).format("0,0") ?? "N/A"}
          </td>
          <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 '>
            <span
              className={classNames(
                item?.status === "Pending" ? "bg-gray-100 text-gray-800" : "",
                item?.status === "InProgress"
                  ? "bg-amber-100 text-amber-800"
                  : "",
                item?.status === "Completed"
                  ? "bg-green-100 text-green-800"
                  : "",
                "inline-flex rounded-full  px-2 py-1 text-xs items-center space-x-1"
              )}
            >
              <svg
                xmlns='http://www.w3.org/2000/svg'
                viewBox='0 0 10 10'
                fill='currentColor'
                className='w-1.5 h-1.5'
              >
                <circle
                  fillRule='evenodd'
                  cx='5'
                  cy='5'
                  r='5'
                  clipRule='evenodd'
                />
              </svg>
              <span>{item?.status || "Unknown"}</span>
            </span>
          </td>
          <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 '>
            <div className='flex items-center'>
              <div className='h-10 w-10 flex-shrink-0'>
                <Avatar
                  disabled={true}
                  alt={
                    [
                      (item?.createdBy?.lastName || "")?.trim(),
                      (item?.createdBy?.firstName || "")?.trim(),
                    ]
                      .join(" ")
                      .trim() || "N A"
                  }
                  src={item?.createdBy?.profileImageUrl || ""}
                />
              </div>
              <div className='ml-4'>
                <div className='text-gray-900 dark:text-gray-100'>
                  {[
                    (item?.createdBy?.lastName || "")?.trim(),
                    (item?.createdBy?.firstName || "")?.trim(),
                  ]
                    .join(" ")
                    .trim() || "N A"}
                </div>
                <div className='text-gray-500 dark:text-gray-400'>
                  {item?.createdBy?.phoneNumber || "N/A"}
                </div>
              </div>
            </div>
          </td>
          <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 text-right'>
            <div className='space-x-1'>
              <ActionButton
                action='viewBulk'
                onClick={dispatchAction(item?._id, "viewBulk")}
              />
            </div>
          </td>
        </tr>
      )}
    />
  );
};

export default RegularizationInitiationsTable;
