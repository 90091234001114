import { DistrictCodes, Nationalities } from "apollo/data";
import lodash from "lodash";

export const GhanaPostRegex = new RegExp(
  `^(${lodash
    .map(DistrictCodes, ({ code }) =>
      code.length > 2
        ? lodash
            .chain(code)
            .split("")
            .chunk(2)
            .map((chunk) => chunk.join(""))
            .join("-?")
            .value()
        : code
    )
    .join("|")})-?\\d{3,4}-\\d{4}$`,
  "g"
);

export const GhanaCardRegex = new RegExp(
  `^(${lodash
    .map(Nationalities, "alpha_3_code")
    .join("|")})-\\d{9}-(\\d|[A-Z])$`,
  "g"
);

export const GeoCodeRegex = new RegExp(
  "^(?!00)\\d{2}-(?!00)\\d{2}-(?!000)\\d{3}-(?!000)\\d{3}-(?!000)\\d{3}-(?!0000)\\d{4}$",
  "g"
);

export const GhanaPostGeoCodeRegex = new RegExp(
  `^((${lodash
    .map(DistrictCodes, "code")
    .join(
      "|"
    )})-\\d{3,4}-\\d{4})|((?!00)\\d{2}-(?!00)\\d{2}-(?!000)\\d{3}-(?!000)\\d{3}-(?!000)\\d{3}-(?!0000)\\d{4})$`,
  "g"
);
