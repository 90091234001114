import { gql, useMutation } from "@apollo/client";
import { useFormik } from "formik";
import { BulkUploadRegularizationsForm, Modal } from "components";
import toast from "react-hot-toast";
import {
  CreateRegularizationsInitiationFormSchema,
  ICreateRegularizationsInitiationFormSchema,
} from "components/forms/bulk-upload-regularizations/schema";
import numeral from "numeral";

const CREATE_BULK_REGULARIZATIONS = gql`
  mutation CreateRegularizationInitiation(
    $region: ID!
    $district: ID!
    $description: String!
    $initiations: [RegularizationInitiationDataInput!]!
    $forced: Boolean
  ) {
    createRegularizationInitiation(
      region: $region
      district: $district
      description: $description
      initiations: $initiations
      forced: $forced
    ) {
      _id
    }
  }
`;

export default function BulkCreateRegularizationsContainer({
  open,
  setOpen,
  refetch,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
}) {
  const [createRegularizationInitiation] = useMutation(
    CREATE_BULK_REGULARIZATIONS
  );

  const form = useFormik<ICreateRegularizationsInitiationFormSchema>({
    initialValues: {
      regularizationsInfo: {
        region: null as any,
        district: null as any,
        description: "",
        forced: false
      },
      initiationsInfo: {
        initiations: [],
      },
    },
    validateOnChange: true,
    validationSchema: CreateRegularizationsInitiationFormSchema,
    onSubmit: async (values) => {
      console.log("values");
      console.log(values);
      await createRegularizationInitiation({
        variables: {
          region: values.regularizationsInfo.region._id,
          district: values.regularizationsInfo.district._id,
          description: values.regularizationsInfo.description,
          forced: values.regularizationsInfo.forced,
          initiations: values.initiationsInfo.initiations?.map(
            (initiation) => ({
              ...initiation,
              hasGhanaCard: initiation.hasGhanaCard === "Yes",
              identityCardType: initiation.identityCardType || undefined,
              longitude: numeral(initiation.longitude).value(),
              latitude: numeral(initiation.latitude).value(),
              numberOfRooms: numeral(initiation.numberOfRooms).value(),
              numberOfMonths: numeral(initiation.numberOfMonths).value(),
              meterPhase: numeral(initiation.meterPhase).value(),

            })
          ),
        },
      }).then(({ data }) => {
        console.log(data);
        if (data.createRegularizationInitiation?._id) {
          toast(
            JSON.stringify({
              type: "success",
              title: "Regularizations Initiated Successfully",
            })
          );
          refetch?.();
          setOpen(false);
        } else {
          toast(
            JSON.stringify({
              type: "error",
              title: "Could not initiate regularizations",
            })
          );
        }
      }); 
    },
    onReset: () => {
      setOpen(false);
    },
  });

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      title='Create Bulk Regularizations'
      size='7xl'
      description='Provide the details to start regularizations initiation process'
      hideActions
    >
      <BulkUploadRegularizationsForm form={form} />
    </Modal>
  );
}
