import { FC, forwardRef } from "react"
import numeral from "numeral";
import moment from "moment";
import { useReactiveVar } from "@apollo/client";
import { currentConfigVar } from "apollo/cache/config";
import { EnvelopeIcon, GlobeAltIcon, PhoneIcon } from "@heroicons/react/20/solid";
import { QRCodeSVG } from "qrcode.react";
import _ from "lodash";

interface BillViewProps {
  ref: any;
  bill: {
    code: string;
    emailAddress: string;
    phoneNumber: string;
    fullName: string;
    profileImageUrl: string;
    ghanaCardNumber: string;
    payer: {
      fullName: string;
      emailAddress: string;
    };
    servicePointNumber: string;
    accountNumber: string;
    geoCode: string;
    contractedDemand: string;
    contractStatus: string;
    meterNumber: string;
    serviceClass: string;
    serviceType: string;
    tariffClassCode: string;
    tariffClassName: string;
    propertyCode: string;
    qrCode: string;
    address: string;
    ghanaPostAddress: string;
    customerCode: string;
    readingSetCode: string;
    readingSetName: string;
    month: number;
    year: number;
    billPeriod: number;
    billStartDate: Date;
    billEndDate: Date;
    consumptionPeriod: string;
    previousBill: {
      readingDate: Date;
      readingValue: number;
      readingEstimated: boolean;
      consumptionValue: number;
      billValue: number;
      totalAmountDue: number;
    };
    currentBill: {
      readingDate: Date;
      readingValue: number;
      readingEstimated: boolean;
      consumptionValue: number;
      billValue: number;
      totalAmountDue: number;
    };
    currentBillAmount: number;
    outstandingAmount: number;
    totalAmountDue: number;
    totalAmountDueAt: Date;
    bills: {
      currentDate: Date;
      previousDate: Date;
      numberOfDays: number;
      consumption: number;
      billItems: {
        title: string;
        unit: string;
        quantity: string;
        unitCost: string;
        cost: string;
      }[];
      billAmount: number;
    }[];
    createdAt: string;
    updatedAt: string;
  };
}

const BillView: FC<BillViewProps> = forwardRef<HTMLDivElement, BillViewProps>(({ bill }, ref) => {
  const { dateFormat, moneyFormat } = useReactiveVar(currentConfigVar);

  return (
    <div ref={ref} className="space-y-6  border-gray-300 border p-4 border-dashed bg-gray-50">
      <div className="flex flex-row items-center space-x-4 justify-center border-b border-gray-300 py-6">
        <div>
          <img
            alt="ECG Logo"
            src={require("assets/logo-new.png")}
            className="h-32 w-32"
          />
        </div>
        <div className="space-y-1 text-gray-900">
          <h1 className="text-xl text-primary-600 font-bold">ELECTRICITY COMPANY OF GHANA LIMITED</h1>
          <div className="flex items-center space-x-6">
            <div className="flex items-center space-x-2">
              <EnvelopeIcon className="h-4 w-4" />
              <span>P.O BOX GP 521, ACCRA</span>
            </div>
            <div className="flex items-center space-x-2">
              <PhoneIcon className="h-4 w-4" />
              <span>0302-611-611</span>
            </div>
            <div className="flex items-center space-x-2">
              <GlobeAltIcon className="h-4 w-4" />
              <span>ecg@ecggh.com</span>
            </div>
          </div>
          <div className="flex items-center space-x-6">
            <div className="flex items-center space-x-2">
              <span className="font-semibold">VAT REG:</span>
              <span>714V000395</span>
            </div>
            <div className="flex items-center space-x-2">
              <span className="font-semibold">Website:</span>
              <span>https://www.ecggh.com/</span>
            </div>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-5 border border-gray-300 divide-x divide-gray-300">
        <div className="col-span-2 grid grid-cols-2 gap-6 p-4">
          <div className="col-span-2">
            <span className="block text-sm">
              Customer Name
            </span>
            <div className="mt-1 block w-full text-sm font-light text-gray-700">
              {bill?.fullName || "N/A"}
            </div>
          </div>
          <div>
            <span className="block text-sm">
              Meter Number
            </span>
            <div className="mt-1 block w-full text-sm font-light text-gray-700">
              {bill?.meterNumber || "N/A"}
            </div>
          </div>
          <div>
            <span className="block text-sm">
              Account Number
            </span>
            <div className="mt-1 block w-full text-sm font-light text-gray-700">
              {bill?.accountNumber || "N/A"}
            </div>
          </div>
          <div>
            <span className="block text-sm">
              Digital Address
            </span>
            <div className="mt-1 block w-full text-sm font-light text-gray-700">
              {bill?.ghanaPostAddress || "N/A"}
            </div>
          </div>
          <div>
            <span className="block text-sm">
              Geo Code
            </span>
            <div className="mt-1 block w-full text-sm font-light text-gray-700">
              {bill?.geoCode || "N/A"}
            </div>
          </div>
        </div>
        <div className="col-span-3 grid grid-cols-2 gap-6 p-4">
          <div>
            <span className="block text-sm">
              Bill Period
            </span>
            <div className="mt-1 block w-full text-sm font-light text-gray-700">
              {moment(bill?.billStartDate || bill?.previousBill?.readingDate).format(dateFormat)} - {moment(bill?.billEndDate || bill?.currentBill?.readingDate).format(dateFormat)} ({numeral(bill?.billPeriod || bill?.consumptionPeriod).format("0,0")} days)
            </div>
          </div>
          <div>
            <span className="block text-sm">
              Bill Number
            </span>
            <div className="mt-1 block w-full text-sm font-light text-gray-700">
              {bill?.code || "N/A"}
            </div>
          </div>
          <div>
            <span className="block text-sm">
              Bill Date
            </span>
            <div className="mt-1 block w-full text-sm font-light text-gray-700">
              {moment(bill?.createdAt).format(dateFormat)}
            </div>
          </div>
          <div>
            <span className="block text-sm">
              Due Date
            </span>
            <div className="mt-1 block w-full text-sm font-light text-gray-700">
              {moment(bill?.totalAmountDueAt).format(dateFormat)}
            </div>
          </div>
          <div>
            <span className="block text-sm">
              Tariff
            </span>
            <div className="mt-1 block w-full text-sm font-light text-gray-700">
              {bill?.tariffClassCode} {bill?.tariffClassName || "N/A"}
            </div>
          </div>
          <div>
            <span className="block text-sm">
              Contracted Demand
            </span>
            <div className="mt-1 block w-full text-sm font-light text-gray-700">
              {bill?.contractedDemand} KVA
            </div>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-5">
        <div className="col-span-2 grid grid-cols-2 gap-6 p-4music">
          <div className="col-span-2">
            <span className="block text-sm">
              Delivery Address
            </span>
            <div className="mt-1 block w-full text-sm font-light text-gray-700">
              {bill?.address || "N/A"}
            </div>
          </div>
          <div>
            <span className="block text-sm">
              Energy Type
            </span>
            <div className="mt-1 block w-full text-sm font-light text-gray-700">
              Active Energy (KWh)
            </div>
          </div>

          <div className="col-start-1">
            <span className="block text-sm">
              Previous Reading
            </span>
            <div className="mt-1 block w-full text-sm font-light text-gray-700">
              {numeral(bill?.previousBill?.readingValue || 0).format("0,0")} KWh
            </div>
          </div>

          <div>
            <span className="block text-sm">
              Current Reading
            </span>
            <div className="mt-1 block w-full text-sm font-light text-gray-700">
              {numeral(bill?.currentBill?.readingValue || 0).format("0,0")} KWh
            </div>
          </div>

          <div>
            <span className="block text-sm">
              Consumption
            </span>
            <div className="mt-1 block w-full text-sm font-light text-gray-700">
              {numeral(bill?.currentBill?.consumptionValue || 0).format("0,0")} KWh
            </div>
          </div>

          <div className="">
            <span className="block text-sm">
              Reading Type
            </span>
            <div className="mt-1 block w-full text-sm font-light text-gray-700">
              {bill?.currentBill?.readingEstimated ? "Estimated" : "Actual"}
            </div>
          </div>
        </div>
        <div className="col-span-3 flex">
          <div className="h-full w-full flex-1 flex flex-row justify-evenly">
            <div className="flex flex-col">
              <div className="flex-1 flex flex-col-reverse">
                {_.map([0, 100, 200, 300, 400, 500], (val) => (
                  <div className="flex-1 flex items-end justify-end">
                    <span className="text-xs text-gray-700">{val} -</span>
                  </div>
                ))}
              </div>
              <div className="h-6" />
            </div>
            {_.map(["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"], (date) => (
              <div className="flex flex-col items-center">
                <div className="rounded-full bg-primary-100 w-4 flex-1 flex items-end">
                  <div className="rounded-full bg-primary-600 w-full h-16" />
                </div>
                <div className="h-6 flex items-end justify-center">
                  <span className="text-xs text-gray-700">{date}</span>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="pt-6">
        <div className="flex items-center justify-center">
          <span className="text-sm font-bold text-center">Calculation Information</span>
        </div>
        <div className="mt-2 ">
          <table className="min-w-full divide-y divide-gray-300 mt-4 border-collapse ">
            <thead>
              <tr className="bg-primary-600">
                <th
                  scope="col"
                  colSpan={2}
                  className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-white sm:pl-4 w-[40%]"
                >
                  Items
                </th>
                <th
                  scope="col"
                  className="hidden py-3.5 px-3 text-right text-sm font-semibold text-white sm:table-cell  w-[20%]"
                >
                  Units
                </th>
                <th
                  scope="col"
                  className="hidden py-3.5 px-3 text-right text-sm font-semibold text-white sm:table-cell w-[20%]"
                >
                  Price
                </th>
                <th
                  scope="col"
                  className="py-3.5 pl-3 pr-4 text-right text-sm font-semibold text-white sm:pr-4 w-[20%]"
                >
                  Amount
                </th>
              </tr>
            </thead>
            <tbody>
              {bill?.bills?.map?.((billX) => (
                <>
                  <tr className="border-b border-gray-200 bg-yellow-50">
                    <td colSpan={5} className="py-3 pl-4 pr-3 text-sm text-gray-600 sm:pl-4">
                      {moment(billX?.previousDate).format(dateFormat)} - {moment(billX?.currentDate).format(dateFormat)} ({numeral(billX?.numberOfDays).format("0,0")} days)
                    </td>
                  </tr>
                  {billX?.billItems?.map((item, idx) => (
                    <tr key={idx} className="border-b border-gray-200">
                      <td colSpan={2} className="py-4 pl-4 pr-3 text-sm sm:pl-4">
                        <div className="font-medium text-gray-900">{item.title}</div>
                      </td>
                      <td className="hidden py-4 px-3 text-right text-sm text-gray-500 sm:table-cell">{item.unit}</td>
                      <td className="hidden py-4 px-3 text-right text-sm text-gray-500 sm:table-cell">{numeral(item.unitCost).format(moneyFormat)}</td>
                      <td className="py-4 pl-3 pr-4 text-right text-sm text-gray-500 sm:pr-4">{numeral(item.cost).format("0,0.00")}</td>
                    </tr>
                  ))}
                </>
              ))}
              <tr className="border-b border-transparent">
                <td colSpan={5} className="h-6" />
              </tr>
            </tbody>
            <tfoot>
              <tr className="">
                <th className="relative" rowSpan={3} colSpan={2}>
                  <div className="border border-gray-300 inset-0 absolute grid gap-1 p-4 py-3">
                    <div className="inline-flex space-x-2">
                      <span className="text-sm">
                        Customer Name:
                      </span>
                      <div className="text-sm font-light text-gray-700">
                        {bill?.fullName || "N/A"}
                      </div>
                    </div>
                    <div className="inline-flex space-x-2">
                      <span className="text-sm">
                        Bill Date:
                      </span>
                      <div className="text-sm font-light text-gray-700">
                        {moment(bill?.createdAt).format(dateFormat)}
                      </div>
                    </div>
                    <div className="inline-flex space-x-2">
                      <span className="text-sm">
                        Account No:
                      </span>
                      <div className="text-sm font-light text-gray-700">
                        {bill?.accountNumber || "N/A"}
                      </div>
                    </div>
                    <div className="inline-flex space-x-2">
                      <span className="text-sm">
                        Bill Number:
                      </span>
                      <div className="text-sm font-light text-gray-700">
                        {bill?.code || "N/A"}
                      </div>
                    </div>
                    <div className="inline-flex space-x-2">
                      <span className="text-sm">
                        Amount Payable:
                      </span>
                      <div className="text-sm font-light text-gray-700">
                        GHS {numeral(bill?.totalAmountDue).format("0,0.00")}
                      </div>
                    </div>
                  </div>
                </th>
                <th className="relative" rowSpan={3}>
                  <div className="flex inset-0 absolute items-center justify-center" >
                    <QRCodeSVG
                      value={bill?.code}
                      size={120}
                      imageSettings={{
                        src: require("assets/logo-new.png"),
                        height: 20,
                        width: 20,
                        excavate: true
                      }}
                    />
                  </div>
                </th>
                <th scope="row" className="pl-4 pr-3  py-3 text-left text-sm font-medium text-gray-700">
                  Current Bill
                </th>
                <td className="pl-3 pr-4 py-3 text-right text-sm text-gray-500">GHS {numeral(bill?.currentBillAmount).format("0,0.00")}</td>
              </tr>
              <tr>
                <th scope="row" className="pl-4 pr-3 py-3 text-left text-sm font-medium text-gray-700">
                  Previous Balance
                </th>
                <td className="pl-3 pr-4 py-3 text-right text-sm text-gray-500">GHS  {numeral(bill?.outstandingAmount).format("0,0.00")}</td>
              </tr>
              <tr>
                <th scope="row" className="bg-primary-600 pl-4 pr-3 py-5 text-left text-sm font-semibold text-white">
                  Amount Payable
                </th>
                <td className="bg-primary-600 pl-3 pr-4 py-5 text-right text-sm font-semibold text-white sm:pr-4">
                  GHS {numeral(bill?.totalAmountDue).format("0,0.00")}
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>
  )
})

export default BillView