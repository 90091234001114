import { classNames, wrapClick } from "utils";
import { gql, useQuery } from "@apollo/client";
import {
  ServiceRegularizationView,
  Modal,
  RegularizationConnectionFeeInvoiceView,
  RegularizationApplicationFeeInvoiceView,
} from "components";
import { useSearch, useNavigate } from "react-location";
import { LocationGenerics } from "router/location";
import { useState } from "react";

const GET_SERVICE_REQUEST = gql`
  query GetRegularization($id: ID!) {
    serviceRegularization: getRegularization(id: $id) {
      _id
      code
      status
      region {
        _id
        code
        name
      }
      applicationFeeInvoice {
        _id
        code
        type
        invoiceTo {
          fullName
          phoneNumber
          accountNumber
        }
        items {
          title
          unitCost
          quantity
          cost
        }
        invoiceItem
        invoiceItemType
        amount
        status
        paidAt
        createdAt
        updatedAt
      }
      connectionFeeInvoice {
        _id
        code
        type
        invoiceTo {
          fullName
          phoneNumber
          accountNumber
        }
        items {
          title
          unitCost
          quantity
          cost
        }
        invoiceItem
        invoiceItemType
        amount
        status
        paidAt
        createdAt
        updatedAt
      }
        createdBy {
        _id
        code
        lastName
        firstName
        emailAddress
        phoneNumber
      }
      district {
        _id
        code
        name
      }
      accountCode
      customerCode
      servicePointCode
      customerData {
        customerType

        organization {
          name
          taxIdentificationNumber
          organizationRegistrationNumber
          organizationRegistrationDate
          organizationRegistrationDocumentUrl
          certificateOfIncorporationDocumentUrl
        }
        representative {
          title
          fullName
          nationality
          dateOfBirth
          gender
          phoneNumber
          emailAddress
          profileImageUrl
          hasGhanaCard
          ghanaCardNumber
          ghanaCardIssueDate
          ghanaCardExpiryDate
          ghanaCardFrontImageUrl
          ghanaCardBackImageUrl
          identityCardType
          identityCardNumber
          identityCardIssueDate
          identityCardExpiryDate
          identityCardFrontImageUrl
          identityCardBackImageUrl
        }
      }
      property {
        ghanaPostAddress
        community
        streetName
        houseNumber
        structureNumber
        landmark
        sitePlanDocumentUrl
        geoLocation {
          type
          coordinates
        }
        owner {
          fullName
          phoneNumber
        }
        premiseCategory {
          _id
          code
          name
        }
        premiseType {
          _id
          code
          name
        }
        activity {
          _id
          code
          name
        }
        subActivity {
          _id
          code
          name
        }
      }
      service {
        serviceType
        serviceClass
        meterPhase
        energyCertificateNumber
        energyCertificateDocumentUrl
      }
      billing {
        neighbourhoodClass
        numberOfRooms
        numberOfMonths
        hasMeter
        meterNumber
        meterImageUrl
      }
      createdAt
      updatedAt
    }
  }
`;

const orderTabs = [
  {
    name: "Regularization Details",
    href: "RegularizationDetails",
    activeStatues: [
      "Pending",
      "Confirmed",
      "Rejected",
      "ApplicationFeeGenerated",
      "ApplicationFeePaid",
      "InspectionInitiated",
      "InspectionCompleted",
      "InspectionFailed",
      "ContractGenerated",
      "ContractSigned",
      "ConnectionFeeGenerated",
      "ConnectionFeePaid",
      "InstallationInitiated",
      "InstallationCompleted",
      "InstallationFailed",
      "Completed",
    ],
  },
  {
    name: "Application Fee Invoice",
    href: "ApplicationFeeInvoice",
    activeStatues: [
      "ApplicationFeeGenerated",
      "ApplicationFeePaid",
      "InspectionInitiated",
      "InspectionCompleted",
      "InspectionFailed",
      "ContractGenerated",
      "ContractSigned",
      "ConnectionFeeGenerated",
      "ConnectionFeePaid",
      "InstallationInitiated",
      "InstallationCompleted",
      "InstallationFailed",
      "Completed",
    ],
  },
  {
    name: "Connection Fee Invoice",
    href: "ConnectionFeeInvoice",
    activeStatues: [
      "ContractGenerated",
      "ContractSigned",
      "ConnectionFeeGenerated",
      "ConnectionFeePaid",
      "InstallationInitiated",
      "InstallationCompleted",
      "InstallationFailed",
      "Completed",
    ],
  },
];

export default function ViewServiceRegularizationContainer({
  open,
  setOpen,
  refetch,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
}) {
  const searchParams = useSearch<LocationGenerics>();
  const navigate = useNavigate<LocationGenerics>();
  const [orderTab, setOrderTab] = useState("RegularizationDetails");

  const { data, loading } = useQuery(GET_SERVICE_REQUEST, {
    variables: {
      id: searchParams.id,
    },
    notifyOnNetworkStatusChange: false,
  });
  const expandItem = () => {
    navigate({
      to: `./${searchParams.id}`,
    });
  };

  const __setOrderTab = (tab: string) => () => {
    setOrderTab(tab);
  };
  return (
    <Modal
      open={open}
      setOpen={setOpen}
      loading={loading}
      hidePadding={true}
      title='Service Regularization Information'
      description='Details of service regularization are shown below'
      size='4xl'
      renderActions={() => (
        <>
          {false && (
            <button
              type='button'
              className='w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm'
              onClick={wrapClick(expandItem)}
            >
              View More
            </button>
          )}
        </>
      )}
    >
      {data?.serviceRegularization?._id && (
        <>
          <div className='block'>
            <div className='border-b border-gray-200 bg-white px-6'>
              <nav className='-mb-px flex space-x-8' aria-label='Tabs'>
                {orderTabs.map((_orderTab) => (
                  <button
                    key={_orderTab.name}
                    onClick={wrapClick(__setOrderTab(_orderTab.href))}
                    disabled={
                      !_orderTab.activeStatues.includes(
                        data?.serviceRegularization?.status
                      )
                    }
                    className={classNames(
                      !_orderTab.activeStatues.includes(
                        data?.serviceRegularization?.status
                      )
                        ? "cursor-not-allowed"
                        : "cursor-pointer",
                      orderTab === _orderTab.href
                        ? "border-primary-500 text-primary-600"
                        : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200",
                      "whitespace-nowrap flex py-4 px-1 border-b-2 font-medium text-sm"
                    )}
                    aria-current={
                      orderTab === _orderTab.href ? "page" : undefined
                    }
                  >
                    {_orderTab.name}
                  </button>
                ))}
              </nav>
            </div>
          </div>
          <div className='flex-1 w-full min-h-[65vh] max-h-[65vh] overflow-y-auto  sm:p-6'>
            {orderTab === "RegularizationDetails" && (
              <ServiceRegularizationView
                serviceRegularization={data?.serviceRegularization}
              />
            )}
            {orderTab === "ApplicationFeeInvoice" && (
              <RegularizationApplicationFeeInvoiceView
                regularization={data?.serviceRegularization}
                refetch={refetch}
              />
            )}
            {orderTab === "ConnectionFeeInvoice" && (
              <RegularizationConnectionFeeInvoiceView
                regularization={data?.serviceRegularization}
                refetch={refetch}
              />
            )}
          </div>
        </>
      )}
    </Modal>
  );
}
